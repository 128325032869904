<template>
  <div id="app">
      
  <b-container id="navigation" style="">
      <b-row class="mx-auto px-3" style="text-align: center;">
     <b-col>
  <a class=""  href="./jungle"><span class="px mlink" style="text-decoration: none;">JUNGLE</span></a>
     </b-col>
         <b-col>
  <a class="" id="FamilyPortrait" href="./gallery"><span class="px mlink" style="text-decoration: none;">GALLERY</span></a>
         </b-col>
                           <b-col>
  <a class="" id="staking" href="./staking"><span class="px mlink" style="text-decoration: none;">STAKING</span></a>
         </b-col>
                                       <b-col>
  <a class="" id="staking" href="./badges"><span class="px mlink" style="text-decoration: none;">BADGES</span></a>
         </b-col>
                   <b-col>
  <a class="" id="sales" href="./sales"><span class="o mlink" style="text-decoration: none;">JPTS</span></a>
         </b-col>
             <b-col>
      <a class="" id="DEX" href="https://www.bananaswap.app" target="_blank"><span class="px mlink" style="text-decoration: none;">DEX</span></a>
     </b-col>
          <b-col>
  <a class="" id="docs" href="https://glmrapes.notion.site/"><span class="px mlink" style="text-decoration: none;">DOCS</span></a>
         </b-col>
 </b-row>
 </b-container>
<!-- <div style="margin: 3rem;">  <btn id="connectBtn" v-on:click="connect()">Connect Wallet</btn></div> -->

  <b-container>
    <a style="margin: 0; padding: 0" href="https://www.glmrjungle.com"><b-img fluid class="" style="max-height: 550px;" src="./assets/logo.png"></b-img></a>
                  <h1 class="w">NEED MORE JPTS ?</h1>
            <div class="zoom py-3"><btn id="connectBtn" class="zoom" v-on:click="connect()">Connect Wallet</btn></div>
      <div id="explain" class="text-center mx-auto" align-v="center">
        <b-container>
        <p class="w"><b class="g h2">Weekly sales:</b><br>

Each week, fill your JPTS bags with our 3 special offers.<br>
<b class="h3">2500 JPTS per week</b><br>
</p>
      <p></p>
      <b-row class="mx-auto py-4 mt-4">

              
                          <b-col>
  <b-card
    title="50 JPTS"
    img-src="./assets/50jpts.png"
    img-alt="Image"
    img-top
    tag="jpts"
    style="max-width: 20rem;"
    class="mb-2 card"
  >
    <b-card-text>
     <b class="lot">20</b> lots of 50 JPTS<br><span class="y">60 $BANANAS per lot</span>
    </b-card-text>
        <b-button href="#" v-if="approved" v-on:click="buy(1)" class="buyBtn">BUY JPTS</b-button>
        <b-button href="#" v-else v-on:click="approve()" class="buyBtn">Approve Contract</b-button>
     <b-card-text class="pt-3">
      Left: <span id="option1Left">1</span>
      </b-card-text>

  </b-card>                           </b-col>
                          <b-col>
  <b-card
    title="200 JPTS"
    img-src="./assets/200jpts.png"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem;"
    class="mb-2 card"
  >
    <b-card-text>
     <b class="lot">5</b> lots of 200 JPTS<br><span class="y">200 $BANANAS per lot</span>
    </b-card-text>
        <b-button href="#" v-if="approved" v-on:click="buy(2)" class="buyBtn">BUY JPTS</b-button>
        <b-button href="#" v-else v-on:click="approve()" class="buyBtn">Approve Contract</b-button>
     <b-card-text class="pt-3">
      Left: <span id="option2Left">1</span>
      </b-card-text>
   
  </b-card>
                          </b-col>
                           <b-col>
  <b-card
    title="500 JPTS"
    img-src="./assets/500jpts.png"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem;"
    class="mb-2 card"
  >
    <b-card-text>
    <b class="lot">1</b> lot of 500 JPTS<br><span class="y">450 $BANANAS per lot</span>
    </b-card-text>
        <b-button href="#" v-if="approved" v-on:click="buy(3)" class="buyBtn">BUY JPTS</b-button>
        <b-button href="#" v-else v-on:click="approve()" class="buyBtn">Approve Contract</b-button>
     <b-card-text class="pt-3">
      Left: <span id="option3Left">1</span>
      </b-card-text>

  </b-card>
                          </b-col>
        </b-row>
        </b-container>

        </div>




    </b-container>
  <b-row>
                    <p class="footer">
                    GLMR APES DAO
                    </p>
                      </b-row>
  </div>
</template>

<script>
import Menu from './Menu.vue'
import { ethers } from 'ethers'
var utils = require('ethers').utils;
import { db } from '../main.js'
import { glmjAddress, glmjABI, bananasAddress, bananasABI, jptsAddress, jptsABI, badgesAddress, badgesABI, badgesMarketAddress, badgesMarketABI, jptsSalesAddress, jptsSalesABI} from './conf.js'

export default {
  name: 'Sales',
  components: {
  
  },
  data() {
 return {
images: [],
isConnect: false,
approved: false,
tokendetail: {},
marketOwned: [],
    };
  },
  mounted() {

  },
  methods: {

        async connect() {

       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      console.log(signer)
      const signerAddress = await signer.getAddress()
      const jptsContract = new ethers.Contract(jptsAddress, jptsABI, signer)
      const glmbContract = new ethers.Contract(bananasAddress, bananasABI, signer)
      const salesContract = new ethers.Contract(jptsSalesAddress, jptsSalesABI, signer)

      console.log(signerAddress,jptsSalesAddress)
      let allowance = await glmbContract.allowance(signerAddress,jptsSalesAddress)
      let allowance2= utils.formatUnits(allowance)
      await alert(`${signerAddress} connected`)
   if (allowance2 < 1) {
     this.approved = false
   }
   else {
     this.approved = true
   }

      let option1Counter = await salesContract.option1Counter();
      option1Counter = option1Counter.toNumber()
      let option2Counter = await salesContract.option2Counter();
      option2Counter = option2Counter.toNumber()
      let option3Counter = await salesContract.option3Counter();
      option3Counter = option3Counter.toNumber()

      let lOption1Counter = window.document.getElementById('option1Left')
      let lOption2Counter = window.document.getElementById('option2Left')
      let lOption3Counter = window.document.getElementById('option3Left')
      let explain2 = window.document.getElementById('explain')
      let connect2 = window.document.getElementById('connectBtn')

      lOption1Counter.textContent = option1Counter
      lOption2Counter.textContent = option2Counter
      lOption3Counter.textContent = option3Counter

      explain2.style.display = "flex";
      connect2.style.display = "none";
      this.isConnect = true;
    },

    async buy(id) {
       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const salesContract = new ethers.Contract(jptsSalesAddress, jptsSalesABI, signer)
      
      let execute = await salesContract.buyTokens(id)
      await execute.wait()
      alert('JPTS successfully claimed!')
      window.location.reload()
    },

    async approve() {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const glmbContract = new ethers.Contract(bananasAddress, bananasABI, signer)
     let approval= await glmbContract.approve(jptsSalesAddress, "500000000000000000000000")
     await approval.wait()
     alert('JPTS Sales Contract successfully approved!')
     window.location.reload()
    },
  }
}
</script>

<style>
@import './style.css';
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>