import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=ee75dd0a&"
import script from "./Badges.vue?vue&type=script&lang=js&"
export * from "./Badges.vue?vue&type=script&lang=js&"
import style0 from "./Badges.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Badges.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports