<template>
  <div id="app">
  </div>
</template>

<script>
export default {
  name: 'Whitelist',
  data() {
    return {
      wallet: '',
      isActive: false
    }
  },
    watch: {
  wallet(value){
      // binding this to the data value in the email input
      this.wallet = value;
      this.checker(value);
    }
  },
  components: {
  
  },
  methods: {
    async checker(value) {
      let wl = [
    "0x119460eCc4C538a02307e5D4f687BB83eDdC5cf9",
    "0x66DC2CCcd7fa6206617A8bDeE3fB6dc21b848A3a",
    "0x5615B91b204E2d5A342972b2AEaafb30Ad3D1184",
    "0xb04660A4228bF1bf86e5e005a6442e9EE92B9bEF",
    "0x4c20fB7f2b432C1b4187Cb3e44471224E0237C6D",
    "0x6282566179c2A70123f178e061796Ad9d4D4DB36",
    "0xC251d55e59125a8Db6285DE32031260fAADbB92A",
    "0x4D9B8CB4CD65b970B085EE891eB20Afc76BE67f9",
    "0xAC692211fA9F5a6134D12e5c4DcA50526Ceca324",
    "0x8150ceef31fE53E2BC31A71FA16557928a02113A",
    "0x27e11D5889AE8DDa637CcCf88F2100Ec187ba557",
    "0xe750295B698Af162743c60a391E8C53d845b179a",
    "0x8dCdb62C6fd8a666D2b8D831bcd923A124361052",
    "0x63De652a02F0C1B3e8416ba4E6D767Ef1D4b3922",
    "0x49EaEe947C2A17401DA8efC21f6a560c9aa79aaF",
    "0x7f42aA5B755cEE36Ae47007eB3e43bFA65519A08",
    "0x1E4478dFf7a83392eA03c0154A21a937DF405230",
    "0xb77fdb6Df5Da718645a103e2Cefb8f3Fa505D706",
    "0xaD6fB295bA769C9f4e5dA09FC1fA1De727C2abE2",
    "0x039f8Cf7Cd8469Bbb1ed0D0caB238736BD9db6Ff",
    "0x3C6Ff8F5649719892Dc11aEC5756837522DedDcC",
    "0xf323D74cD60115658C4617e4276ff1364bb9930e",
    "0x60eA90A7f699452062E407a0F2B063537d638728",
    "0x25Cd984a313162b437aBD7bE6f029D06143b610c",
    "0xc379D85EBFF7C0480A353fC31951E5DeB1A0B9b3",
    "0xfC0A48e52f3a3130268871E6A7d6bED015763B72",
    "0xBEB7bBfe61dD7EfdE611088daE618126f5ca78ed",
    "0xf11A3703226a02825F23A02c1b3663be8fc46060",
    "0xF0E94b5cc8dE1FA42560542ed371921440eCE869",
    "0x56AC099B065984bAb3401e05cFC4A8D63ff8FC2d",
    "0xf2DC787F600e1180bea8319A9CDb579F4a7D084e",
    "0x485cA0cB5a83FC0370214e2539648432fcE69627",
    "0x6FB3af602BBdc75C03d1C5A442E2637615CC9b4f",
    "0x9A19259Ca0F3FbAd2cCf0CF4B8d0eDD308AF2eb4",
    "0xE7846949d13003DBaa2f98D555426A2A3DCa5e6f",
    "0x0952F955299e3356A97DAF48821432eA635289e4",
    "0xC037bA45ce2EB1BDD7208C9e457c46C8D6EC800e",
    "0x5037d2132F8BfBB29b53993535B344d9f6A804eF",
    "0xe477B58FE8B45Cffec5310920A4D3B6764A729F5",
    "0x8Ae715efBF4dDc088Fe5989D1eC37664b0a07ADe",
    "0x2E3ab2977DD852658341e8f50a21Aa0B0Ee16fF7",
    "0x9d6A33C5d7761ab9e82dCC94F248b6C121eAC7De",
    "0xA31cB340fb16D90C5C7c349575e24DC1165124Dd",
    "0xca345a15925C14e3DEb0AeDa5EBe5b772986Dcb4",
    "0x00e4E5a8a77865e423e071CAfE38Dbc39214BBBa",
    "0x2a5bce84a4A7Ea57c8e2b6dFf6B54307c1819aa0",
    "0x2C3619482FCe6a81a8954ca967986F4eAa29eE17",
    "0xFE99B4EB232498d30Cc167BFCEC4133E2B9c6a80",
    "0x597aAB0305ed1D37d1a009854303e927C468b52c",
    "0x231a19C04c6B314A395c915fFcf5d49e1E95cc34",
    "0x582ca7e0B63F1f87add6A49459a28b590F9cA3d5",
    "0x0E304aD208cF62b90956CE200A5bfBd82e63E4CF",
    "0x5a1848a228391145D5B43e0ed0b52fCc51e2BA75",
    "0xAF3c20498ff9AB190af36764428FCE9017C54758",
    "0x1A46803858978dC22f3aE1e72C4f39aF97A5CEeD",
    "0x3EB8b2F4584c642a43eD5caD2F83182de41B5dE2",
    "0x033D34C92Bbcfe226217276f39aa7022e9756488",
    "0x221B9632Ad1698D07c6132acf6D761eF335BD488",
    "0x4906FB37c20479Fb334c4b105769a98f590E841e",
    "0xaF88C01d2b906CD935C1Fe320f27722c1E40a300",
    "0x60215D79243992b5D495954daF0cdf43F8fBC99f",
    "0xe39095edf3C8b9BE677F5d8BeAaA27D8A5ED1676",
    "0xDcbA398932886aEbd2158fd403a4E0e7b5d5953a",
    "0x083b11518A1897Da3b38Ae98030Ec98442280aB4",
    "0xFfafBcbAB1fF94e95eA75324AD744ac15DaC44Ad",
    "0x850F284e7F7D787e7B0ec1D5CA16fba5Df9c43f0",
    "0x3835607F119fdc26Bb2f0BF9D7416adbC15eF008",
    "0x51A4545Bf235564F263Fd165728C06c0C0296413",
    "0x6B98039CDc4f9b22d09D81F319AD6023089b2439",
    "0x84bDBB7F61e516661EFDcB27C2B480BBf08c1330",
    "0x8D447C54B10792C9167c90B56c452a0149A26981",
    "0x2B2e9D15264452e032FBAf851d38bA0d9d4CB536",
    "0xff3b31900b971FbD797042F8372F9BA89AAC1183",
    "0x9B5e0f33FCD35Bf8216E699139319fF2ABe13d6F",
    "0x85799fF1c86f89FE9fB07773d1d240763b6039Ce",
    "0x445a247A5C3806E2DE2E52d0C0c0c4CA82388321",
    "0xD594D0E5d9ab9420ec6287712953f9Ea57447E3d",
    "0xfC6EcEc7999c081a969B2b07BDEA19Cfb52160e0",
    "0x9dF2e5625E1E41abA65F40E76b4DE7AFd2818C81",
    "0xdE826c431E5F4d98d918C30c2CB67a87B64425a9",
    "0x367d894CdEB7E129D7337f7e75362b2bf15fB56f",
    "0x289ecfB2fC5b50D8540D8Fc78f4d9BCa77dB54CC",
    "0xB3dBbB4199B6D89024182927B76b558eb67dB23b",
    "0x6daDB084794f563BD18316fd1DCf07bCe4DbF27A",
    "0x5FA81B2216f50DE76Cb3ff52060816E1421600A7",
    "0x7AC418a3b70735f06E259fB8C80fD29cFCa25cd4",
    "0x0Ab73f03Daa2Ea32bB9a2Fe7e0858294B8C98e25",
    "0xA0f9a2C7Df0d18fc84FC5c6C3b009e988645fb12",
    "0xF59D51b788198ef0673c798EFA16750dbF674D59",
    "0x1f137F133DF19d65669165A0963fE72485c7FD0a",
    "0x114e4c9847E0E9fD3F040eB0DED5624151D399AA",
    "0xE45d3dB2646123A3aeB320F18e900965Bd6e5855",
    "0xDa2444E9433eF0Dd46B34Fa01d998D6dde12F4de",
    "0x1E9442C78bf24a5fFAbf4B97dE91fFa070328e47",
    "0x7831Abb4Ab43f1C811956daeE414b707A87494C3",
    "0xCa5CaA294D558eBC386964FDE9562e1BDCA05144",
    "0x63b38CfD568b5BA7f3e124b5a70603262D834e5f",
    "0xC4e109029C27F3A08292f58FfaEc807E0f8b86FF",
    "0x30178fd6F019F6BaFbC599afaadeaec8b6228B40",
    "0x37F3288C7eA466E935eB9B26845A71F3a4c1dcF7",
    "0x9018218D675a6bcaaF979C5fF30d8430EF3D4747",
    "0xED71B9A9b5633e9d31A0986693658CBbf23c3c1B",
    "0x6Dab8Ca307d6616dF076f5680C4618faE43fCd85",
    "0x703101c9dceb93356c60E557e9Da27b631D61fF9",
    "0xE1225c438907515313e2c9C827E6ADdfA1bab90b",
    "0x5d9E1538838849ac4e1F652A64f60e67fe344d30",
    "0x80e0310b95Cb13e886C712e6A7E5059bc9619F38",
    "0x2AC0842cdbcAbB70A7f95a257b4391260E09e15b",
    "0xEC69e1E76Beaa3690D4899dB8a796a5C03E6BB88",
    "0x0Ea0C964F591CdD6C20D992F143cEFB13b839859",
    "0x79b85E96d83a700DBB933db044969a90024797CE",
    "0x0F3C5e934effdC85dF5De68E0f96039deE07D4Bb",
    "0xA10A602A7a32aA1Ec2224ACb241663af305A66Bb",
    "0x5e39CFfde235C66e4ABe7160e842a57A13FA99A8",
    "0x75b2ef8D85123c587F026df4178520C8dD4F0d29",
    "0xaF18cF2c12707332C4332B17223198f9F94ff733",
    "0xF9F9A2A6221b91311dB5dbeaDBf497d06f1Ac6B5",
    "0x8CdD027052456C553552e79d9C3d416C473d3625",
    "0x35C328c49FC2D9dFb653cd8cc8dD66772044e10F",
    "0x3248f8fF55d1818BF516f004b400FA2A49e90b20",
    "0xE735473D42655B2a5F885F035e0bc16cb23Bf566",
    "0x7DAD72F9360D28DB78C959105FF252FF0584e479",
    "0x1fd8563c594cB7480919a5470EBc21EfbAcDEC9b",
    "0x06F0967E4410ae65d1Dd1f912f5BcCD1843F0f8e",
    "0x14D359F8763E3e871af51c14140bB689948Fd189",
    "0xe84B03a94da5Fd9c3b17E584693E7e45A24D7589",
    "0x62ECC14B236f229CCc27dcb80C32D7dC7888303B",
    "0x343Fceb790033402392405aE282A8D224Cd70b25",
    "0x54bB7d1cBFFDf200Fef069d50B6FD55C9A77749A",
    "0x742A3d4D02d7B66cAD4Ce9233720dEc4cAb8D5Bd",
    "0xF662caBF1Dff43C0101AE21d691Cfe753865bcB7",
    "0x00AAD25ab2c0bD62D201Eab6638741b54265487d",
    "0x4fBD3305580f192c938C3FD8f5285761442ccFe7",
    "0x938831aC1f7e754D5981D536Cca420e75Eb10D10",
    "0x09430e45f57a7ee101c00fd259F7b103cD4a7c53",
    "0x22ABAec9110D7b61770E39626454138D51bDDd8B",
    "0xBCae95258486c983225a710572b5628a08eC78C3",
    "0xBFEF116e957c04409067B7c6C0D18FFb0634ecaB",
    "0xb2A213ac4F5074c50987089075Ae9642Fd59cDe8",
    "0x143B5D7E1A11B5dC301D97A51264EB73dD5A37EC",
    "0xb11B919186768E6c8F7d891fa113CDBeE974dbfb",
    "0xE8eE339E760fa2f202602CB2d5E4a592cCa186E2",
    "0xc519CaBB354ff6746cFb335ba2127FD9D5de744B",
    "0xBEAAF573C71F50086F4E9491D2338abfd023446D",
    "0xD91E89E68871e04d9fF8F56299810C1824a331D8",
    "0x24D8D73f52630C513e5CF006FB8962332b53F29e",
    "0xbD0E02Bd11431489988cDB6C6c72D750032780ac",
    "0x1e09B6D1a62787698F16e28350F992b4A6e0f6B7",
    "0xd6B28c6e0dfa65C974363f0a9f8AB634eD313b6C",
    "0x583E24928E55b16E2F7CBaeEC80D99751174cBD2",
    "0x7A60487B4710797804170E7E1A1FCc0503A1b3D2",
    "0xE75Cd5fD2225fa04dcd360b599CF14D2f8B7a973",
    "0x0DE7529e4e15F06DaE3B0cBaE977f3C18eC39356",
    "0x865FA73770e2Eaa7e109f605dD2AF59EA34aA676",
    "0x893f5002BA91E27ea41c3a2c63b0B69a80A8D124",
    "0x9E9982f36a03a916B2f101E4E25ECe1798518d33",
    "0x72641eee16b0baCDb9d5D9e25530125018E4Cb45",
    "0x02C0dd25046b05922D4341A4B92Fedc77f73f0eb",
    "0x43584e7e0B4a2189b3CdBcc9dD2A7434ceff2C56",
    "0x6873E1F05E8661AE9dfd51c7CE92CaB6223Fd7a3",
    "0x8F1F65f769D13eb46BACd96224A09F8E9595d048",
    "0x8c6fDBca2070590D7f2cE5b2c1ED1C305D968E5D",
    "0x58E02a7AA5FCf710358Bcf26D93D80Dae782c6FA",
    "0xbf82BA66185194b0e343Ab7A5aECa8e91d8C13e2",
    "0x4F48b5C821Aa78C37b4194870d71B6F64dCF0B8A",
    "0x552c233fb66cB329A595eD3E86E45A5cC330F782",
    "0xa9C6B2493e1266de7970314af12Ec4F0d005CeFA",
    "0x309471398aDFa2B136970583AD0db66294F035e3",
    "0x3643Fb74ACddAa73E456212f1065d9D4eBA4837a",
    "0x3b38FfFDf4D5024021760cbc205A3Ac6DE2a76fc",
    "0xA7b56B5dfCe40A22d0AC8C872fbC0AE64D2E05ac",
    "0x903bC8891583Cf162ab51fEA4a09563E33675123",
    "0x3013999a39d108E08193058054eac9db213A85bE",
    "0x2A9b6f644B4632F4055129Ae062ac6bF4A739795",
    "0x400FF215eb976F7a68F44071e5DcEb345e41EBf6",
    "0xE45d3dB2646123A3aeB320F18e900965Bd6e5855",
    "0x870486aa51fe280672b2c498674B6412a37896e7",
    "0xE35aED6Af771dde5f7b365517DE7c7c3aAA1419d",
    "0x111da5bd993636d82adA5788B10C9fB9af85fA30",
    "0xAb08b58B6B101d97E8096F013f674989cccaEaF6",
    "0x562bc488dA4100C8Fb2c7558f5947C60ba243f43",
    "0x7c90E2B16905110E6A6b1b6ba22f006F067910a4",
    "0xadD515a5E37F702BCdF6C0a4ccCcEAE392177b20",
    "0x422f493B0f398e9120e05C687065AbaCC1D1dF73",
    "0xbd9f70E2B1D741b4925c36A0315A5a9aC26BE776",
    "0xFBdf1BB037dbbE25daff53e07c15905B00d080aE",
    "0xe4f100b9D50a2fCD41bd475b8a33198779fe5DD7",
    "0x26B5d8Fa5D9b770FF03e41f8e0f0299DD5FbF186",
    "0xb3a84B5DE292cB72703b1A7f84C9C7A857ba712b",
    "0x35aAF0cB3D3A25BC26c76E39062Ba2336021d463",
    "0xE35aED6Af771dde5f7b365517DE7c7c3aAA1419d",
    "0x4a9025B24B4936Fdd385d0516d3A73c6Dc51E45e",
    "0xE747d435022e3122ac944213239656312cF18498",
    "0xf7aB78CddeBFD5F0815d196E8B44838c6376a049",
    "0x778b5C5734a3ec363FBE9F220698Fd4866Dec40d",
    "0x1DD9D048bf59b3c363a6F885534d5Fed525c854F",
    "0xBDF864EBcD4E9d607f1f800c5Dc23A7696E3EeB2",
    "0xb6D6d89A510053C809e310668dE67B3a838F7988",
    "0xE95aE62B1391e8737aF0eaE957AAe41063F43c39",
    "0xD8696bBD8ce60804F0fC9FdbB73B517E72855F2c",
    "0x8Bc1BBBFcB36fD933E3862822b58fbaC51f812D5",
    "0x03CAf265dc426B25378aE2000368159C4f66A30a",
    "0xabA0493fc8e624AB899b5Dfb27b364cf031936c5",
    "0x6500868ab045433784046cfd0aD6337AF505CdE7",
    "0xb0aEa420c496613D92fE6E26145ab8d5A385F33e",
    "0xa3Ae1aff4248cD0992F6F4eEB33Ad8FE1CEeE96a",
    "0xB5D93Dbeff89f84058DEC857E8b60817B6C4E62d",
    "0xF8091d43fE307C01648c4752C4d372ac9B864024",
    "0x47C536E85f6B283935442Cb62f94c4168a4E5ed6",
    "0xF1cD9e05bfCdc78Cd1851b2d1B0A3b0f615524a8",
    "0x491fFC953E1aB2751817ff90A5f32694a4841474",
    "0xd3dE1f8Aa8e9Cf7133Bb65f4555F8f09cFCB7473",
    "0x4C0398b106Bc617A935f63b28a550A923aaDE6bf",
    "0x4E3F601dB69aA0b01745Ee05aCe50527daaA052a",
    "0x117fB889A01b9Cb614B4164599F960bB116c654A",
    "0x26860caAaEa40bDf507772B1f386f11cD97BB4B3",
    "0xc2367aC3A2c9B61629a6202C9053ac41FfcC1e68",
    "0xd88f34679b914C4C677DdC78ae0bAD7cdF6760cc",
]

 let nft = ["0x903bC8891583Cf162ab51fEA4a09563E33675123","0x583E24928E55b16E2F7CBaeEC80D99751174cBD2", "0xb3a84B5DE292cB72703b1A7f84C9C7A857ba712b", "0x85799fF1c86f89FE9fB07773d1d240763b6039Ce", "0xc1ee245897aAA5Ba6cBAE9CE132a8736b221311B"]

      if (value.length >= 42) {
    if (nft.includes(value)) {
        let nftC = window.document.getElementById('nft')
        nftC.classList.remove("inactive");
      }
      if (wl.includes(value)) {
        let ok = window.document.getElementById('ok')
        let notok = window.document.getElementById('notok')
        notok.classList.add("inactive");
        ok.classList.toggle("inactive");
        console.log('ok')
      }

      else {
        let notok = window.document.getElementById('notok')
        let ok = window.document.getElementById('ok')
        ok.classList.add("inactive");
        notok.classList.toggle("inactive");
        console.log('not ok')
      }
      }
    },
  },
}
</script>

<style>
    *{margin:0;padding:0;border:none;}
    html,iframe,body{width:100%;height:100%;}
    html{overflow:hidden}
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      html,iframe,body{min-height:100%;}
    }
.inactive {
  display: none;
}

#ok, #nft {
  color: #339933;
  font-size: 22pt;
}

#notok {
  color: #cc6633;
  font-size: 22pt;
}


input[type="text"]{font: 15px/24px 'Muli', sans-serif; color: #fff; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

:focus{outline: none;}

.col-3{float: center; width: 27.33%; margin: 40px 36%; position: relative;} /* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #fff; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

.effect-20{border: 1px solid #ccc; padding: 7px 14px; transition: 0.4s; background: transparent;}
.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.3s;}
.effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #4caf50; transition: 0.4s;}
.effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
.effect-20 ~ label{position: absolute; left: 14px; width: 100%; top: 10px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;}
.effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: #4caf50; transition: 0.3s;}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  background-color: #330000;
  max-width: 100vw;
   
}
a {
 margin: 0 3rem;
 font-size: 30pt;
 color: #339933;
 text-decoration: none;
font-family: 'Bree Serif', serif;
}

p {
  font-family: 'Bree Serif', serif;
}
a:hover {
color: #cc6633;
text-decoration: underline;
	}
#logo {
   max-width: 33vw;
}
.footer {
  margin-top: 3rem;
   font-size: 13pt;
 color: #cc6633;
 text-decoration: none;
 font-family: 'Bree Serif', serif;
 text-align: center;
 margin-right: auto;
 margin-left: auto;
}
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>