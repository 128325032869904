<template>
  <div id="app">
    <a href="https://www.glmrjungle.com"><img id="logo" alt="logo" src="../assets/logo.png"></a>
                    <h1><p style="margin-top: 0rem; color: white;">Whitelist Check</p></h1>
        <div class="col-3 input-effect">
<input class="effect-20" type="text" v-model="wallet" placeholder="Your wallet address">
            <label></label>
            <span class="focus-border">
<i></i>
            </span><br>
            
        </div>
        <p id="ok" class="inactive">This address is whitelisted</p>
        <p id="notok" class="inactive">This address is not whitelisted</p>
        <p id="nft" class="inactive">This address won a free NFT</p>
               
                                       <p class="" style="max-width: 100vw;">
                       <span class="" style="margin: 0 3rem;">
                       <a class="links px" href="https://www.bananaswap.app" target="_blank">DEX</a>
                       <a class="links px" href="https://twitter.com/GLMRApes" target="_blank">Twitter</a>
                       <a class="links px" href="https://discord.gg/H9DH3z2bTV" target="_blank">Discord</a>
                       <a class="links px" href="https://t.me/+qGh0InPSPc1iMTNk"  target="_blank">Telegram</a>
                       </span>
                    </p>
                         <p class="footer">
                    GLMR APES DAO
                    </p>
  </div>
</template>

<script>
export default {
  name: 'Whitelist',
  data() {
    return {
      wallet: '',
      isActive: false
    }
  },
    watch: {
  wallet(value){
      // binding this to the data value in the email input
      this.wallet = value;
      this.checker(value);
    }
  },
  components: {
  
  },
  methods: {
    async checker(value) {
      let wl = [
    "0x119460eCc4C538a02307e5D4f687BB83eDdC5cf9",
    "0x66DC2CCcd7fa6206617A8bDeE3fB6dc21b848A3a",
    "0x5615B91b204E2d5A342972b2AEaafb30Ad3D1184",
    "0xb04660A4228bF1bf86e5e005a6442e9EE92B9bEF",
    "0x4c20fB7f2b432C1b4187Cb3e44471224E0237C6D",
    "0x6282566179c2A70123f178e061796Ad9d4D4DB36",
    "0xC251d55e59125a8Db6285DE32031260fAADbB92A",
    "0x4D9B8CB4CD65b970B085EE891eB20Afc76BE67f9",
    "0xAC692211fA9F5a6134D12e5c4DcA50526Ceca324",
    "0x8150ceef31fE53E2BC31A71FA16557928a02113A",
    "0x27e11D5889AE8DDa637CcCf88F2100Ec187ba557",
    "0xe750295B698Af162743c60a391E8C53d845b179a",
    "0x8dCdb62C6fd8a666D2b8D831bcd923A124361052",
    "0x63De652a02F0C1B3e8416ba4E6D767Ef1D4b3922",
    "0x49EaEe947C2A17401DA8efC21f6a560c9aa79aaF",
    "0x7f42aA5B755cEE36Ae47007eB3e43bFA65519A08",
    "0x1E4478dFf7a83392eA03c0154A21a937DF405230",
    "0xb77fdb6Df5Da718645a103e2Cefb8f3Fa505D706",
    "0xaD6fB295bA769C9f4e5dA09FC1fA1De727C2abE2",
    "0x039f8Cf7Cd8469Bbb1ed0D0caB238736BD9db6Ff",
    "0x3C6Ff8F5649719892Dc11aEC5756837522DedDcC",
    "0xf323D74cD60115658C4617e4276ff1364bb9930e",
    "0x60eA90A7f699452062E407a0F2B063537d638728",
    "0x25Cd984a313162b437aBD7bE6f029D06143b610c",
    "0xc379D85EBFF7C0480A353fC31951E5DeB1A0B9b3",
    "0xfC0A48e52f3a3130268871E6A7d6bED015763B72",
    "0xBEB7bBfe61dD7EfdE611088daE618126f5ca78ed",
    "0xf11A3703226a02825F23A02c1b3663be8fc46060",
    "0xF0E94b5cc8dE1FA42560542ed371921440eCE869",
    "0x56AC099B065984bAb3401e05cFC4A8D63ff8FC2d",
    "0xf2DC787F600e1180bea8319A9CDb579F4a7D084e",
    "0x485cA0cB5a83FC0370214e2539648432fcE69627",
    "0x6FB3af602BBdc75C03d1C5A442E2637615CC9b4f",
    "0x9A19259Ca0F3FbAd2cCf0CF4B8d0eDD308AF2eb4",
    "0xE7846949d13003DBaa2f98D555426A2A3DCa5e6f",
    "0x0952F955299e3356A97DAF48821432eA635289e4",
    "0xC037bA45ce2EB1BDD7208C9e457c46C8D6EC800e",
    "0x5037d2132F8BfBB29b53993535B344d9f6A804eF",
    "0xe477B58FE8B45Cffec5310920A4D3B6764A729F5",
    "0x8Ae715efBF4dDc088Fe5989D1eC37664b0a07ADe",
    "0x2E3ab2977DD852658341e8f50a21Aa0B0Ee16fF7",
    "0x9d6A33C5d7761ab9e82dCC94F248b6C121eAC7De",
    "0xA31cB340fb16D90C5C7c349575e24DC1165124Dd",
    "0xca345a15925C14e3DEb0AeDa5EBe5b772986Dcb4",
    "0x00e4E5a8a77865e423e071CAfE38Dbc39214BBBa",
    "0x2a5bce84a4A7Ea57c8e2b6dFf6B54307c1819aa0",
    "0x2C3619482FCe6a81a8954ca967986F4eAa29eE17",
    "0xFE99B4EB232498d30Cc167BFCEC4133E2B9c6a80",
    "0x597aAB0305ed1D37d1a009854303e927C468b52c",
    "0x231a19C04c6B314A395c915fFcf5d49e1E95cc34",
    "0x582ca7e0B63F1f87add6A49459a28b590F9cA3d5",
    "0x0E304aD208cF62b90956CE200A5bfBd82e63E4CF",
    "0x5a1848a228391145D5B43e0ed0b52fCc51e2BA75",
    "0xAF3c20498ff9AB190af36764428FCE9017C54758",
    "0x1A46803858978dC22f3aE1e72C4f39aF97A5CEeD",
    "0x3EB8b2F4584c642a43eD5caD2F83182de41B5dE2",
    "0x033D34C92Bbcfe226217276f39aa7022e9756488",
    "0x221B9632Ad1698D07c6132acf6D761eF335BD488",
    "0x4906FB37c20479Fb334c4b105769a98f590E841e",
    "0xaF88C01d2b906CD935C1Fe320f27722c1E40a300",
    "0x60215D79243992b5D495954daF0cdf43F8fBC99f",
    "0xe39095edf3C8b9BE677F5d8BeAaA27D8A5ED1676",
    "0xDcbA398932886aEbd2158fd403a4E0e7b5d5953a",
    "0x083b11518A1897Da3b38Ae98030Ec98442280aB4",
    "0xFfafBcbAB1fF94e95eA75324AD744ac15DaC44Ad",
    "0x850F284e7F7D787e7B0ec1D5CA16fba5Df9c43f0",
    "0x3835607F119fdc26Bb2f0BF9D7416adbC15eF008",
    "0x51A4545Bf235564F263Fd165728C06c0C0296413",
    "0x6B98039CDc4f9b22d09D81F319AD6023089b2439",
    "0x84bDBB7F61e516661EFDcB27C2B480BBf08c1330",
    "0x8D447C54B10792C9167c90B56c452a0149A26981",
    "0x2B2e9D15264452e032FBAf851d38bA0d9d4CB536",
    "0xff3b31900b971FbD797042F8372F9BA89AAC1183",
    "0x9B5e0f33FCD35Bf8216E699139319fF2ABe13d6F",
    "0x85799fF1c86f89FE9fB07773d1d240763b6039Ce",
    "0x445a247A5C3806E2DE2E52d0C0c0c4CA82388321",
    "0xD594D0E5d9ab9420ec6287712953f9Ea57447E3d",
    "0xfC6EcEc7999c081a969B2b07BDEA19Cfb52160e0",
    "0x9dF2e5625E1E41abA65F40E76b4DE7AFd2818C81",
    "0xdE826c431E5F4d98d918C30c2CB67a87B64425a9",
    "0x367d894CdEB7E129D7337f7e75362b2bf15fB56f",
    "0x289ecfB2fC5b50D8540D8Fc78f4d9BCa77dB54CC",
    "0xB3dBbB4199B6D89024182927B76b558eb67dB23b",
    "0x6daDB084794f563BD18316fd1DCf07bCe4DbF27A",
    "0x5FA81B2216f50DE76Cb3ff52060816E1421600A7",
    "0x7AC418a3b70735f06E259fB8C80fD29cFCa25cd4",
    "0x0Ab73f03Daa2Ea32bB9a2Fe7e0858294B8C98e25",
    "0xA0f9a2C7Df0d18fc84FC5c6C3b009e988645fb12",
    "0xF59D51b788198ef0673c798EFA16750dbF674D59",
    "0x1f137F133DF19d65669165A0963fE72485c7FD0a",
    "0x114e4c9847E0E9fD3F040eB0DED5624151D399AA",
    "0xE45d3dB2646123A3aeB320F18e900965Bd6e5855",
    "0xDa2444E9433eF0Dd46B34Fa01d998D6dde12F4de",
    "0x1E9442C78bf24a5fFAbf4B97dE91fFa070328e47",
    "0x7831Abb4Ab43f1C811956daeE414b707A87494C3",
    "0xCa5CaA294D558eBC386964FDE9562e1BDCA05144",
    "0x63b38CfD568b5BA7f3e124b5a70603262D834e5f",
    "0xC4e109029C27F3A08292f58FfaEc807E0f8b86FF",
    "0x30178fd6F019F6BaFbC599afaadeaec8b6228B40",
    "0x37F3288C7eA466E935eB9B26845A71F3a4c1dcF7",
    "0x9018218D675a6bcaaF979C5fF30d8430EF3D4747",
    "0xED71B9A9b5633e9d31A0986693658CBbf23c3c1B",
    "0x6Dab8Ca307d6616dF076f5680C4618faE43fCd85",
    "0x703101c9dceb93356c60E557e9Da27b631D61fF9",
    "0xE1225c438907515313e2c9C827E6ADdfA1bab90b",
    "0x5d9E1538838849ac4e1F652A64f60e67fe344d30",
    "0x80e0310b95Cb13e886C712e6A7E5059bc9619F38",
    "0x2AC0842cdbcAbB70A7f95a257b4391260E09e15b",
    "0xEC69e1E76Beaa3690D4899dB8a796a5C03E6BB88",
    "0x0Ea0C964F591CdD6C20D992F143cEFB13b839859",
    "0x79b85E96d83a700DBB933db044969a90024797CE",
    "0x0F3C5e934effdC85dF5De68E0f96039deE07D4Bb",
    "0xA10A602A7a32aA1Ec2224ACb241663af305A66Bb",
    "0x5e39CFfde235C66e4ABe7160e842a57A13FA99A8",
    "0x75b2ef8D85123c587F026df4178520C8dD4F0d29",
    "0xaF18cF2c12707332C4332B17223198f9F94ff733",
    "0xF9F9A2A6221b91311dB5dbeaDBf497d06f1Ac6B5",
    "0x8CdD027052456C553552e79d9C3d416C473d3625",
    "0x35C328c49FC2D9dFb653cd8cc8dD66772044e10F",
    "0x3248f8fF55d1818BF516f004b400FA2A49e90b20",
    "0xE735473D42655B2a5F885F035e0bc16cb23Bf566",
    "0x7DAD72F9360D28DB78C959105FF252FF0584e479",
    "0x1fd8563c594cB7480919a5470EBc21EfbAcDEC9b",
    "0x06F0967E4410ae65d1Dd1f912f5BcCD1843F0f8e",
    "0x14D359F8763E3e871af51c14140bB689948Fd189",
    "0xe84B03a94da5Fd9c3b17E584693E7e45A24D7589",
    "0x62ECC14B236f229CCc27dcb80C32D7dC7888303B",
    "0x343Fceb790033402392405aE282A8D224Cd70b25",
    "0x54bB7d1cBFFDf200Fef069d50B6FD55C9A77749A",
    "0x742A3d4D02d7B66cAD4Ce9233720dEc4cAb8D5Bd",
    "0xF662caBF1Dff43C0101AE21d691Cfe753865bcB7",
    "0x00AAD25ab2c0bD62D201Eab6638741b54265487d",
    "0x4fBD3305580f192c938C3FD8f5285761442ccFe7",
    "0x938831aC1f7e754D5981D536Cca420e75Eb10D10",
    "0x09430e45f57a7ee101c00fd259F7b103cD4a7c53",
    "0x22ABAec9110D7b61770E39626454138D51bDDd8B",
    "0xBCae95258486c983225a710572b5628a08eC78C3",
    "0xBFEF116e957c04409067B7c6C0D18FFb0634ecaB",
    "0xb2A213ac4F5074c50987089075Ae9642Fd59cDe8",
    "0x143B5D7E1A11B5dC301D97A51264EB73dD5A37EC",
    "0xb11B919186768E6c8F7d891fa113CDBeE974dbfb",
    "0xE8eE339E760fa2f202602CB2d5E4a592cCa186E2",
    "0xc519CaBB354ff6746cFb335ba2127FD9D5de744B",
    "0xBEAAF573C71F50086F4E9491D2338abfd023446D",
    "0xD91E89E68871e04d9fF8F56299810C1824a331D8",
    "0x24D8D73f52630C513e5CF006FB8962332b53F29e",
    "0xbD0E02Bd11431489988cDB6C6c72D750032780ac",
    "0x1e09B6D1a62787698F16e28350F992b4A6e0f6B7",
    "0xd6B28c6e0dfa65C974363f0a9f8AB634eD313b6C",
    "0x583E24928E55b16E2F7CBaeEC80D99751174cBD2",
    "0x7A60487B4710797804170E7E1A1FCc0503A1b3D2",
    "0xE75Cd5fD2225fa04dcd360b599CF14D2f8B7a973",
    "0x0DE7529e4e15F06DaE3B0cBaE977f3C18eC39356",
    "0x865FA73770e2Eaa7e109f605dD2AF59EA34aA676",
    "0x893f5002BA91E27ea41c3a2c63b0B69a80A8D124",
    "0x9E9982f36a03a916B2f101E4E25ECe1798518d33",
    "0x72641eee16b0baCDb9d5D9e25530125018E4Cb45",
    "0x02C0dd25046b05922D4341A4B92Fedc77f73f0eb",
    "0x43584e7e0B4a2189b3CdBcc9dD2A7434ceff2C56",
    "0x6873E1F05E8661AE9dfd51c7CE92CaB6223Fd7a3",
    "0x8F1F65f769D13eb46BACd96224A09F8E9595d048",
    "0x8c6fDBca2070590D7f2cE5b2c1ED1C305D968E5D",
    "0x58E02a7AA5FCf710358Bcf26D93D80Dae782c6FA",
    "0xbf82BA66185194b0e343Ab7A5aECa8e91d8C13e2",
    "0x4F48b5C821Aa78C37b4194870d71B6F64dCF0B8A",
    "0x552c233fb66cB329A595eD3E86E45A5cC330F782",
    "0xa9C6B2493e1266de7970314af12Ec4F0d005CeFA",
    "0x309471398aDFa2B136970583AD0db66294F035e3",
    "0x3643Fb74ACddAa73E456212f1065d9D4eBA4837a",
    "0x3b38FfFDf4D5024021760cbc205A3Ac6DE2a76fc",
    "0xA7b56B5dfCe40A22d0AC8C872fbC0AE64D2E05ac",
    "0x903bC8891583Cf162ab51fEA4a09563E33675123",
    "0x3013999a39d108E08193058054eac9db213A85bE",
    "0x2A9b6f644B4632F4055129Ae062ac6bF4A739795",
    "0x400FF215eb976F7a68F44071e5DcEb345e41EBf6",
    "0xE45d3dB2646123A3aeB320F18e900965Bd6e5855",
    "0x870486aa51fe280672b2c498674B6412a37896e7",
    "0xE35aED6Af771dde5f7b365517DE7c7c3aAA1419d",
    "0x111da5bd993636d82adA5788B10C9fB9af85fA30",
    "0xAb08b58B6B101d97E8096F013f674989cccaEaF6",
    "0x562bc488dA4100C8Fb2c7558f5947C60ba243f43",
    "0x7c90E2B16905110E6A6b1b6ba22f006F067910a4",
    "0xadD515a5E37F702BCdF6C0a4ccCcEAE392177b20",
    "0x422f493B0f398e9120e05C687065AbaCC1D1dF73",
    "0xbd9f70E2B1D741b4925c36A0315A5a9aC26BE776",
    "0xFBdf1BB037dbbE25daff53e07c15905B00d080aE",
    "0xe4f100b9D50a2fCD41bd475b8a33198779fe5DD7",
    "0x26B5d8Fa5D9b770FF03e41f8e0f0299DD5FbF186",
    "0xb3a84B5DE292cB72703b1A7f84C9C7A857ba712b",
    "0x35aAF0cB3D3A25BC26c76E39062Ba2336021d463",
    "0xE35aED6Af771dde5f7b365517DE7c7c3aAA1419d",
    "0x4a9025B24B4936Fdd385d0516d3A73c6Dc51E45e",
    "0xE747d435022e3122ac944213239656312cF18498",
    "0xf7aB78CddeBFD5F0815d196E8B44838c6376a049",
    "0x778b5C5734a3ec363FBE9F220698Fd4866Dec40d",
    "0x1DD9D048bf59b3c363a6F885534d5Fed525c854F",
    "0xBDF864EBcD4E9d607f1f800c5Dc23A7696E3EeB2",
    "0xb6D6d89A510053C809e310668dE67B3a838F7988",
    "0xE95aE62B1391e8737aF0eaE957AAe41063F43c39",
    "0xD8696bBD8ce60804F0fC9FdbB73B517E72855F2c",
    "0x8Bc1BBBFcB36fD933E3862822b58fbaC51f812D5",
    "0x03CAf265dc426B25378aE2000368159C4f66A30a",
    "0xabA0493fc8e624AB899b5Dfb27b364cf031936c5",
    "0x6500868ab045433784046cfd0aD6337AF505CdE7",
    "0xb0aEa420c496613D92fE6E26145ab8d5A385F33e",
    "0xa3Ae1aff4248cD0992F6F4eEB33Ad8FE1CEeE96a",
    "0xB5D93Dbeff89f84058DEC857E8b60817B6C4E62d",
    "0xF8091d43fE307C01648c4752C4d372ac9B864024",
    "0x47C536E85f6B283935442Cb62f94c4168a4E5ed6",
    "0xF1cD9e05bfCdc78Cd1851b2d1B0A3b0f615524a8",
    "0x491fFC953E1aB2751817ff90A5f32694a4841474",
    "0xd3dE1f8Aa8e9Cf7133Bb65f4555F8f09cFCB7473",
    "0x4C0398b106Bc617A935f63b28a550A923aaDE6bf",
    "0x4E3F601dB69aA0b01745Ee05aCe50527daaA052a",
    "0x117fB889A01b9Cb614B4164599F960bB116c654A",
    "0x26860caAaEa40bDf507772B1f386f11cD97BB4B3",
    "0xc2367aC3A2c9B61629a6202C9053ac41FfcC1e68",
    "0xd88f34679b914C4C677DdC78ae0bAD7cdF6760cc",
    "0x00fff7e689a4ed9668c9207f55c8d68bab1cb507",
    "0xb65fb2eD30Ee8Fb966a80f369f2F735Bbe6A39FD",
    "0xbeA39FE72B47A4087E3259a6605a82b729817550",
    "0x9b7c0Fa477db07FB813ea73da9e040f7212578bf",
    "0xE614065a4Ac6d478F141Db4595325b537ffA2EA5",
    "0xbd47A345Cfa680ef97b2FdCC2801CF9bC41B3cFD",
    "0xC0E0e7122c163a8c70c9858E8674C51Fb7117e88",
    "0x73b0d4b2507f867C3ECf567128A2265B084D918c",
    "0x7d40F7EDF2f32dFC57af8Fa2E8a5680a7699f90B",
    "0x66dCAf3fc7127eff875e6Cc1975E7E31A0B48388",
    "0xE211b39C5ec0C223830B69e17F3295afD497C7e7",
    "0xed46c5da97b76e6cee54843ba3a167f136eec180",
    "0x950FEB37e6d1F41815D0e67Cc829c15ea28146Ef",
    "0xa4606441f58029aaCE9B51D770742dDd8516134b",
    "0xEA9f3A24907F46Dd25F6eE2d7475b3ca7F96a901",
    "0xb1882bb6f466BBE9E12c73fa37B2E12e655DB270",
    "0xB5462e14432E27A63CDA06Cf7a0595Bfed600AA1",
    "0x784d70C22FF313aFa105A6016C339A144409a3fb",
    "0xD60279cDd758EFc2C1469b74067CA8098926f848",
    "0x12C3A430099dCf05Cc6efd0f219eD49d5B03D1E4",
    "0x771531212607b497EdFC45E32Eb6418B58B62596",
    "0x9210cBB688eb68D0c0DEc88dA9D7EE8A6b8b4DeC",
    "0x82416c5229337a9c5F558A7410B82C9024AdbA75",
    "0xc6E79D9D28C2254C462CD690C663f8Ba42e01a1F",
    "0x824f76f76a2D777122C51cFEBdE4053C04fa192B",
    "0xAc6127ec3c59f5F21c73329584758f34F6B6A95E",
    "0xB88055EdB5c215cE0d572586CE53e051D5817a77",
    "0xc5310e8b3910127F8E3Dab418a52A91A291813F0",
    "0x8b9F8a0330C67DcceEc401A76df6506145c48A47",
    "0x0DA41A73B8877f7ab00bbBB1Dae0E4B4f8823794",
    "0x8802719Ea260B4b75B07e63AD4DE0e83C8c557d0",
    "0xC94B91463124b784321B30EDFed60531C9aE663b",
    "0x871850F700f46A91190A14f647ac517b1237bA32",
    "0x15FF31534bF11Aef21aB883e3708fA3a9E4B76A3",
    "0x5d4fbbA4ac7072aDB68b7BbE8dD9dbaC8833D254",
    "0x44F5d6a4E8598DBe887F8f3A77395501B2F05F70",
    "0x535B1dA051EBf66eB49Ae94E045e139817Ab95a3",
    "0x1AE59dc8829d1834CE7EE65F350a23Ec78345ac2",
    "0xE513351a6371bDf745401fFCeF201eca44A1A512",
    "0xEE887Bd772Eb07DC56ec2F37fc6076e77CA321d0",
    "0x3f545f480417614F67A7D11FaAc6B08e830685ce",
    "0x542E35Ac7fBE2E04F8758b243e042A072eaCa12A",
    "0x1Eb7647d7bba84217C419b1edA2557eB489be797",
    "0x7603c40b04aE8d4A82Aedbccb5C3Cd81B6681895",
    "0x8266109a1B4Ccbf3291F96045f6F13d32525Ca1A",
    "0xC3CaF7F38f0BB0b59577aC4f1D9f5eE6CCf5FA4A",
    "0x79e665559F2De2C34DD5c657e86170cA7950eF6b",
    "0xb89b222Fc04c53C129F8E51cF1CF6aBE5C916433",
    "0xF1cD9e05bfCdc78Cd1851b2d1B0A3b0f615524a8",
    "0xAbd5f31f203953198D87CD85AF1D509FaD948808",
    "0x4D7fE4da8967668e7c051b892f7360A5b8CceB0c",
    "0x57690eD81fd03c1149478942Bf63733Ce37C6D06",
    "0x8502E776c46e75E5f88095BF77E4De03610053E4",
    "0xe3c22B9688e1F059785414EC2a86e48e82385e2b",
    "0x43D7996491005fd480Eb3B9a0FF8652BC0a66aA3",
    "0x94E5a9f00Aab169F822af1Ef64cb436FE1a59BEA",
    "0x23B49b987e66B8B79e392f2eD782FAd0aD2afeb0",
    "0xe477B58FE8B45Cffec5310920A4D3B6764A729F5",
    "0xC0CDbF8Be3E031687652444090ad95d06B2d75e9",
    "0x693aB260de2a31c1B5D7DC9cb253B87eD9b1f385",
    "0x09e3e2cb2f56370dcb7d3337b36c337768892552",
    "0x7ADe4A34953235C079Cd70AD4Bd394FcdB90b070",
    "0xaBb471a61F41884269E448b8E6dC108439bCa2d8",
    "0xA8952CA1Db47402c6d9C2fc6a946f25D1d00F5c0",
    "0x9f2CA1Eb50DfF9A35dBC3EB40bA1f7Ea1A634Dc7",
    "0xa24C3DEe07F185A6491f1c9565027640E6773D2D",
    "0x71dA1A32D1837DDe8dD3Fd63Ab4EcCb16fF2b587",
    "0xeD5dcdFD207Fc473e6942eC15c52BAA9BdCF0E9d",
    "0x9f6971f905D582167D30281893190ECcb10bf16e",
    "0xD99438f318C8beBF112b9435cC6342857D71D12e",
    "0x87ea04970159898C10A1CA526a1cc29d138422ED",
    "0x484b21699356435dce2655857650ac54346a88fd",
    "0xAf1DE83D68Ca459dfF313423101aEE24988467d1",
    "0x9D425Ee90595Cf76cA6573262791B77C24bbc344",
    "0x2116E08647739670136075dcb31D5b311923b3ef",
    "0xdbAa7e3b56DEb42902a405943AF4f3B030F37fe7",
    "0xCC8356A48083C08C4792869bA6Eb089CeCb21797",
    "0xc20b79f82Dd805E9A5f1fd188C09cafAa4344d71",
    "0xE9F7C92649802E84E9B597dfabDCc82e61080B70",
    "0xE4Bc54B3e2ebc2Cc6AE4a87AB64f1ea3cf0ddA9a",
    "0x2163072ec749b2496bf33E32a96aa340EFA6aCc3",
    "0xDcbA398932886aEbd2158fd403a4E0e7b5d5953a",
    "0x75B7B817eF8dB38aF930d837B7Ce8F2DE340214B",
    "0x646c4ec43B6C8f4F5a90383f4fd2cAe54FCAC67D",
    "0x70429f1Ea295f7dC3392ac2d98B56A8FE5F1d0f8",
    "0x55a9ACABEF84A288cCD5051D199a4481d779eF20",
    "0x94aFa18b12add823C5858372638DFa7De1Dc9210",
    "0x8B444977E897916E55d6b7bfA8c0A68FCd0a038C",
    "0x671B882Fc0912117a1eA0eF50CF8E764b6A7B7b8",
    "0x1b7dCE4c0a2ae4272B7Ba741cE09a8A77c56c76E",
    "0x6492f536d2A759345061a8364D47B200156A149c",
    "0x0F079a86C26A33059357558FEF29d43F98434ee2",
    "0x58936e3741b12E4f664D3b658FDB2241317c1482",
    "0xF9167631EdC824c19c68287257DB965aAE3a2241",
    "0x1509ce86FbDa5d4145713C4163544796A2Cb58EE",
    "0x40e8390E7CC1139Dda2d4f56CeE5a781B15Be9f3",
    "0x003390323Ee540568bD06c3B974956Db8636b48A",
    "0x2373812E8A9B47B0d530cB065a930b9EEbae4E3f",
    "0x93d34ED9571Cf2C22b2ce17a9b7328946A3dC05f",
    "0xAB3B4E2987EC64B54EF4073d37A3D5343Fddc45f",
    "0x51A4545Bf235564F263Fd165728C06c0C0296413",
    "0x5a9FDF9895711d6F8DB6c125F396c1318Be1D12c",
    "0xfED29004F116b173CFfcBb52eeDb95a70149d92F",
    "0x9f1842a615a14BCe5723f4C5019D6f67c889B632",
    "0xEa4Bf827643463f25e2c73b35184367C6aCa13ae",
    "0x0973B1f774d33C7b3448D815a91E92c5C2A6d33C",
    "0x8F31C1EC215AaB84Cea49B5d12b3F6656E00b102",
    "0xaCd5e70e28A7cE84d8bcb53cF9E51c1349765F2a",
    "0x14e63BeFc30B986420a97b2fF659ae7cFb790c29",
    "0x797BFe9528A2e32f00Ea79d4ac9F123c5bF939A1",
    "0x8c3664d49A88C03a4625035078F96dBcc2382A4F",
    "0x3e709F2Cfe8c7d0054DF3e3315eB3032Abf57411",
    "0xcdCa32d3E7AbdCDF3Be265A57406537221C19bE8",
    "0x101F40257d468c27a6C94Ea34f5a4968597e9423",
    "0x907deB507998aD5F01281F3DA1e6033b36675AD1",
    "0xd7Cc57BfE31C7A9abC7edCE68F660329828694ec",
    "0x32C34947894a37ad2F1c4832CFC40EC2BE1a1F37",
    "0x1C1494461Eba57e3b99808FB48EC68D6C65D3D52",
    "0xa56cc2CC3FaB1A0Da80268Ea83eFfBac3dAaeDC0",
    "0x969755B393B1302778e4CE1853519d9F7543aa5a",
    "0x11DF25C097840E44AE1aF4DFE74A61DC9e71e301",
    "0x46EC6711dD46910eA4E0452021F75AC5109D7176",
    "0xe0886deeB12c78283841EDCB82C12ad4808bC334",
    "0xa104112a5Cdb86C0Ac03D7Ab8224CD6E4757DDeF",
    "0xa2920abaA03b696C7B90486600D578b455E11609",
    "0xa0bf0C295D6ac1bb167837406426D388De7a5E43",
    "0x0F0DE13D623bE54aD5e2D73841618b2708c7C669",
    "0x3e709F2Cfe8c7d0054DF3e3315eB3032Abf57411",
    "0x1f137F133DF19d65669165A0963fE72485c7FD0a",
    "0x886F136D699EBf04E06038183C445578E69AcC6F",
    "0x35Df63A1fb1Ab3D8Ae9691C31dA62A09F25EEe58",
    "0x49A188EF1926874DA8106dC6b8B75306C7A57DF5",
    "0x1B5e68816D269BA4be0bB70baF3A06B1fF0a6e29",
    "0xf7a9683eb47467C895F388da181b47CA7c33003B",
    "0x6e8bD380B56CccCD78b03ED091B675E55d9dD367",
    "0x53b5be475188BdB5f8A965767157F2f3E503e0AB",
    "0x7f2419837012518ca4F8cFaD1876acA0Cf549AA9",
    "0xcEc429a2CDcD172A15E4302C759c60901ABeEb41",
    "0x9087B06bB520664B60Bd09FaCEC09851Ab98c4C5",
    "0xd2C1aD3342a0a08056B84709ab4464fE94B1b4Ad",
    "0x3005f018C6DaAcf5424fa508E89C48473293378a",
    "0xc46fb5933453705Ef2B0Ca032ed5ACa63A6f594e",
    "0x59e3447dCBe4C9B30B4178583883A3a9B259Ee51",
    "0xAFf85fbaeFEfb64fA0A535de424117A0a9d35B74",
    "0x0f6830cdd9E77419d9389dfbca581dcB952B39dA",
    "0xFE371E3402fB1573468056855121B1ed56f0FE5b",
    "0x353b8c464041f282De4eD94e95C042E9a785b9f8",
    "0x7d703179bd792a4b5EfF2064451434cf09994f8E",
    "0x67Fa73Cb67a4d961C0d14faF45483899192d8ac3",
    "0xFA3A9Cf5E42d8abFb23151B4D53372a5AaeF5e35",
    "0xBf4F83E2302F9B300Ea0Ff35F0a8f76B12b88C94",
    "0x4b4B041e48929309D8B70835389d14D2C3F432F0",
    "0x0aE728b264E1ee0503aA16b7809De56ce276c062",
    "0x57AEeae02071Ea167D34940C61dED48AB3214AB9",
    "0xFA2A93e51c6A18aC6C1A40E10e0b083770f48Dd9",
    "0xbb43c8F298ddB8094529b9708C53b732B3eDbB6a",
    "0x52A3a7D1d84772ba1384EE0385275FE16A8513cC",
    "0x769855ae7cE220e7DFF3505052e7a2962176dE62",
    "0x010ee094bC9F3D1EC54C5fd54143104c29d0546E",
    "0xa9fc9605e2956065b12568285b6196824a6b37eb",
    "0xf27694C159eee5E592634e5BA98A981764554e0D",
    "0xd80E09a94F9CD49fA6C8757205197c52f42A8A03",
    "0x6De113EB941587ad572995C19f64aAAD11f93909",
    "0xaf291efF1CB81058eCA29b065D384cC597C6A249",
    "0x6BbB1764C63cdeA612C70722bD0aa5c14986aE1c",
    "0xCF9B74b417f92F2f87D984D18Eb33a7a5472C81e",
    "0x76047F3f333383cdC1A270a060949A6D464F1CF9",
    "0x5cAEb6dC41D6c7a33B0605a60c14C23fD004c51B",
    "0x30bc0FFb975e7b9f81A386AEC05b1cc4795d851a",
    "0x541ee6bfdbf9E4df06D5eB3F53B10435c7f8Ce64",
    "0x9372A1a64dF08efeceBf90187605A64c3889dce4",
    "0xB565f31Fb338FADD21896bBC5D6723d0671cC0FC",
    "0x1b811F23ae4D6466E3678e6475cDc8A4F61f8AB3",
    "0x02b75d1c0FDc6287a4B79B5dc07c2e7AB1d3b96E",
    "0x82719b2D37459Fa15aaea00Ea90DE60387a1F86d",
    "0x6FDb4f92D88d02FA8f160Ca3D642625D0550B23c",
    "0xdc2e491e81767fdc3ef3cf1ba255af4e0323b820",
    "0x66ec57D0Bf78DD98FabB5c7E95B9E59049a10876",
    "0xd915ae55ecE5C258bC9f17e2725D4CF668E2E072",
    "0xf157857c114342FEC0c8A3ffcE8835Cb80Bf0210",
    "0x70BDA66C2AA664d9161DC2Ac82E10Ac3E6DA40f0",
    "0x554106A719da52fbaaAbf192747073b9ac53dB54",
    "0x5f84b02e2e6b3d03c8cd8fad3cab0fabd221e550",
    "0x1f4E2AF4473942436a37c99ff9558aAA617F8b8A",
    "0x44D142fa5EaD699Cb550CA9D2ab0022B74853362",
    "0x55b7b4AC9eA2fDDBD651fa8bBF5DEdd0e3BA12F7",
    "0xE0850282Fa00b3F07D5f5D5837AA9bFFc2A69f4C",
    "0x25B12Fe4D6D7ACca1B4035b26b18B4602cA8b10F",
    "0xC2fF1C8910Ab5B8e9e9ef0ac081Ce6A69d56Af2E",
    "0xbd9bF41e9e7b45a7B5225C14586D28DA94BE82ca",
    "0xc655B6c4c19840bF545Deb16832321636C0E3370",
    "0x96eb9dea378Be0725ae85C92e251d112F42F040F",
    "0xbf03aaE6f2c2DFa39a536fbD13296c48A2a868a7",
    "0xE12b73F325A264525258fb2ba877ff0A0dd21A62",
    "0xf48dAcc080795548486a4805E3C0BCCc8ddc7837",
    "0x4b88AE6Aeccdda91caf221B78b5BD32E00809592",
    "0x88d2447B8110AE81e47e7FDB021be99Ba43888Db",
    "0xf601560c21E7E4Eb45606b5F2955d03d5A2be4B8",
    "0x587F9D73F8AF604374b050ec6f291b4d09EFFE90",
    "0x88Ca9704275451F24c0D717bbdFB36253701F7bB",
    "0x617005d9ae7C9f3c6B764c81b3E4Dd4E667d8586",
    "0xe17Be3Fe72B3a9f3825b9be9C9e2E6AEb37fbD28",
    "0x553c22860c858f3C87EB55081b936EeCE1E4d55c",
    "0xF985feC8C02e22bCAD28F91D39FAafed2496e134",
    "0x046C795bA1276bAd74Ef1d2172eeB0C43c54E6B9",
    "0x24495fB8655C92D81eF8f7d4D3a20369B2477b71",
    "0x0f2e3E67cB000993d07E60261748963d3F4BD6d9",
    "0x1Ec950A58307add69266d45Cf2f34c838Ae7899e",
    "0x26d18Bd5fA215F455ba87b606Ea1C782DEF61C43",
    "0xa56cc2CC3FaB1A0Da80268Ea83eFfBac3dAaeDC0",
    "0x58DC0781D8F8152bdF416E770a2f07F000747a6b",
    "0xFD5BE9a052b60d642a9968302bE63c489F24B0C1",
    "0x133EbBD437e5672eB5E57B5D5B27Da7532FA40F8",
    "0xca9910A63cFaa94f4b18b92bC4126c9a05D9eDa2",
    "0x08E20EE52328Ca5D82Ce0A2Af38F2BCAAbdfD09b",
    "0x6101a1410f25ff6cDB6e74D5EFa7f5A06E24eaFA",
    "0xF593262b1d2c450280d64c5aa58ffD0Ab0144197",
    "0x7f42aA5B755cEE36Ae47007eB3e43bFA65519A08",
    "0x3580dBb22BA2e1464C332e0b96aEc58040f27216",
    "0x23c8c3e0383FD01ef629198d137733719F688998",
    "0xCEC8f0cEa8aAF657F95Ff288EA0113cF82Ce9A5D",
    "0x1289b59D69dBAFa8b97d0643d2Bf04E8a01f9839",
    "0x8F8942885DB6B4340A0bd5a6bF741cFf914D4a8B",
    "0xa56cc2CC3FaB1A0Da80268Ea83eFfBac3dAaeDC0",
    "0xa6a0F9cBC3c305ce8F66C5F003a0713C54A47cDF",
    "0x66B172ef92FC377E775347C2aDc0fdefB75F6788",
    "0x32acb29b344BC6693F5A96c2fD6848F84C2B98E7",
    "0x850C23EC9E524Ef64f7c8532cc6ECe44C981D13F",
    "0xc367ceC9E99142C28247AE3f150912717186F274",
    "0x6e020cdF925D242bc060d61F6f8596E46bDAd759",
    "0x52CbfC6E7fFf4d6D15e67303077bD5418d261d03",
    "0xf9B95bBaF5EEaAFD39be5C0903Be9F49A71e8814",
    "0xEb18d9Fcf336eD1AD0b60A96E570d03292094504",
    "0xF2BD728e577b79b87CFa96b1629F8662763F7754",
    "0x8c6ae33527D22ec6B583562A713F7Ad2e2f70CbF",
    "0xe4CCaFE917Fd97698f64054f3D12aA05Db8f2733",
    "0x4f184251c40056fE7b8ABbf040F8f30803357799",
    "0xc5C096a642bAB3a435485F4E1864dF179670D4cA",
    "0x96421077299712f704A4b0d1564B90CcFa6c9d0E",
    "0x71a49320e012b58f1a063825041A96BC33C563a5",
    "0x925770dFae5137d52F8Bf83174ACb56FffA0891c",
    "0xbbD3A06795FF7399597Aac3Df8631d698650C92D",
    "0x9C8414946c1D3AB7A4b2f837DCfF30B48C77A822",
    "0xd9D0b2F086B853823315d13E5C8Cf2ddC5E8b741",
    "0xF6B087d269408007D351654986174d1BB22Ef32C",
    "0xe7fCddd3aD9177f8Ad48A888e171284BF925C079",
    "0x6f356bC9ccE65FC0feC76B7Cd7e87e8F6e255D92",
    "0x50a18F280Dba56F33747736a144F2129ecc796F4",
    "0xd26213a5e241Cf0B5a9E554824eca4855b28f3D4",
    "0x73A5d4b25AB98F6c2aB246bFeE8040445c59d99d",
    "0x2AE02587a2b86FBd4502C5322154E97908d7279f",
    "0x338cBa3b652821dbf07eb89B072fb1e16F0e5c0b",
    "0x60dA056B7fE2D0326de91d1f6B12e3F7662Ec292",
    "0x3C98385E712F24BD7c0f3f2364CffA60b1BedCEf",
    "0x00cA36902021DE6195c098674B908793073d11ac",
    "0xF83Df30498F6bBEC0a90E7651d25c9890eF88ab3",
    "0xF734B109e9e12705c8816bacfa0E76A2B53c0abC",
    "0xab7bf9dfc36b54c3c7fb795720b4ca6a5ed0c10e",
    "0x9b13a9b60e4b3406e6171b32309991141df9ff31",
    "0x3751e4B82c5e2FF03e5EA97f570814b24E817E6d",
    "0x20Af961CE495C409E0Db3659DcDEa5464755E2aB",
    "0x9C330a97c3DD093F4b514aF6CC2f531AC0Cb084b",
    "0xDe5b2B3Cb7Cbfea49Be49A7b7Df831Eb9B14B8BB",
    "0x9d472b37f2b2b2d94952a36fbfc9225ede944c52",
    "0xdce219E778691a91Dd887CB66C2b73520f5D50Db",
    "0x71B60dC2675dacfD8F2daafB04FCc1E348086a15",
    "0x2B58eb7543944f48e6304F6C25C200Af9ECD424f",
    "0xd9589558a72683bF6A1bCCF39cC31d8078156645",
    "0xB778dA36bB3effdd1C433Cc4E5d6E1A25F76Db93",
    "0xea00c41082C03daE849Afa17473A5983F0E4c31C",
    "0xD1C986A3c2d4DCd4b2ab507f06B5063356c11C9f",
    "0xCCf035087E9035e42955AeB366291B4ffc56321B",
    "0x2DB75ed3436E8AEc35554fBf7A35A5873620E87E",
    "0xcC7E9681eFDFC8e2400Af0f87428EB86235b381B",
    "0x78B2108724cE91d401557B126D6a58bD0F086394",
    "0x0e604a19F125524439B943cfFc896A6D783FAC5D",
    "0xF17fA2B6Ef9f8C2D913AfA9abd1Ede7A8623934b",
    "0xAbf5AC28C751F01239BCC95403E340e69a53252B",
    "0xf2eA41eDC71209A2a844dfE6C9535341B9215FA7",
    "0x76d39a7912e834Bfa0358A5611C8725063E19125",
    "0xAA1cbe8308CcFc0A8b718DC63ee5b6D75666B501",
    "0xBa03317aAD4E8a72C2659C6fD75AA571905B6b3F",
    "0x8c9E67826C4Ea99eCE7e028AcDdDD2f90b9d94be",
    "0x0eF66486557150981f8350716e6eB5c4ac744C76",
    "0x692f3D3Bb0957De6188A62a0EF11463cB1202797",
    "0xC47f727821Eae3a75BcF1Bc8Db3F210C7FB1E147",
    "0xA7a7aBad6FBE05D82130eE398eD0Ab4920c0521C",
    "0xF1Fd06ED12FB7684AE2CC646f0656619526d58e8",
    "0x4162DaF10e992AFBf5cE57744F95a34EfB021b3a",
    "0xC3622742A0a1635e2Aca62308B858Bb364446db7",
    "0xdf1Ba5b8369CD3AF56C5ed6C8EB2Bf1fA47A5b24",
    "0xd74a680F57d2146f86026f9e59459Db0d4C155B3",
    "0xa4606441f58029aaCE9B51D770742dDd8516134b",
    "0x14be767c01B0947D6c0660e6bD6Bf66ea09D6Dfa",
    "0x02F6B2671fAddb9041283fa180eCa74a5FDa3Dc8",
    "0xC651aAf5Cf0EeD7d919c6DAe7Adc72d8Cd9f62bb",
    "0x0066a7fe1d82cD97cdFEd6242927B2fdf959B8bB",
    "0x331050857E567c9574E1D7358d57B75A812a0A59",
    "0xb4AF342D0be913D01ce64fefB9b73593425747BA",
    "0x527349bb08fB45b73bbD7A0767Ced57e51A374C2",
    "0x4f765b9FCD43712404dC32CeE0bd4cAdf073CC05",
    "0xd4Da55B076D6cad1E7Bd67CFDE92c44419179beD",
    "0x6AeA23377b49178ea97d97975EcB8a3683CA1938",
    "0x0C14B646CEa888b58f6265614cea02219DCB291f",
    "0x898b42EFE4085C09b2f2Efc35c6Ff5b46F2E5947",
    "0x6DF2f62d1F43850A28aFA258ad354b688c4ef1Dc",
    "0x88C208ED24527661c15550E6C66dB590a81c2f38",
    "0x5D9836339b459b7AD803Ab90AbD0d5e51A296d67",
    "0xf9c416490DE2ce892C342e84023e68400D366841",
    "0x2F09bdb2072CE9f402CEb8Be4d66251714551aFC",
    "0x018C069F01ea6b27DFA266512a058C8Df17DC0BD",
    "0x9C585BA6e697B33332D1BB8EdBB33166509AA9Bf",
    "0x8aB478b9FB2c158AE0D8d2b6Dc5006bb1b272683",
    "0x02741Bc8d0700fcF55B354733D53a71F1B73752B",
    "0x5Bf3d6D785Ce543AcA6c8A328Fb0EA41001c08F7",
    "0x2A6339998D7DE47c5559a039befb4bC1B0692A60",
    "0x189C415968eA2f62f6430d5050Df872221633340",
    "0xdB36D48F553Da5953ADDFeD3858CE2B336B5f47D",
    "0xF60c002653BDdEB38c3C5b40c2b3EEB8DbC96375",
    "0xE53c64a90187E20F04BB10e2a188b170Fd5E8728",
    "0x0fC3303c8F24409dC5b8960107034897aC2A239E",
    "0x2F896C44E0231785A24Da8f84F70B3446Cee18c9",
    "0x30c469826A1114ebe19c928D64DCf5294E452446",
    "0xF25814A8eb85D2144C9453ec9dC8C503cEE1F261",
    "0x14be767c01B0947D6c0660e6bD6Bf66ea09D6Dfa",
    "0x7796D7E2F04b2854cB32F52C6014bEe89fD93C18",
    "0xE0e7480347E6CC9b40D6C1c40FdBebe121D7f0E6",
    "0x6C344Ffb390a18775840E1e18E2F05C856323455",
    "0x465d1b81c6b47eCaB5E96c326cf7C3FC180e5ad3",
    "0xa3c632c8b38cF307dCcE1d5702c8E3333593a37e",
    "0x1AC3407956F4fCf384766bF45E7dB34Bb84f9c98",
    "0xb5e5FDaDFDdB911d3CD80452f21378DD8bb046E8",
    "0xA3B26c977ED4e1C5c3b425E52b3349bFeADDE76c",
    "0x1AC3407956F4fCf384766bF45E7dB34Bb84f9c98",
    "0x6459562c997728Ba46EDFAF231822DaaD0464110",
    "0x1e64B0852fAA1aE7C02141c8d7228B275202ebAF",
    "0x5FA6c2CdC923631B99F42E7Ab0AC44800FBA1419",
    "0xd7D42460c34Fc0a2d254B102fD9dAb8543191f6f",
    "0xC0E0e7122c163a8c70c9858E8674C51Fb7117e88",
    "0xa3Ae1aff4248cD0992F6F4eEB33Ad8FE1CEeE96a",
    "0xEf0c51CE9328bb94C5F4B255E066D84Bf16719A2",
    "0x95414dEC23E738997927C8F6697E66bc2A4F45B2",
    "0xDeDEC9aF2c98904E6D23523251e2EDf6Cb6a68Ce",
    "0x8B7B1dDAA9cf313264Da924a9384a0A1351689dc",
    "0x78870644501feBB91a7E60f91c829A15131CfBe5",
    "0xF0a4A6B0987c7e99482Ea09BE0a0674D19627125",
    "0x74FCc36004736DBa755f83004Bca75DA9EeF59fd",
    "0x0820a296E27945E1470088aA516c35f85F5a2F25",
    "0xEa6e03f4a1Daef671A4dD23126Ea83D2C2219d1b",
    "0xD3D6726070B2B7498D67C7C084b1391DdEF17Ac2",
    "0x05D8788C9c3ad793EB020234d868b7F814ACee23",
    "0x1C1494461Eba57e3b99808FB48EC68D6C65D3D52",
    "0x8aB478b9FB2c158AE0D8d2b6Dc5006bb1b272683",
    "0x406149cb165Db1a0c1C8AA8d9310C4F36a7d6864",
    "0xdCb559921cc271FD81930Eeccd8513A66AabB504",
    "0x98d81cb144d206e03a31e8445b466b2e1b854af0",
    "0x1b1546d47aB23eBd9bD06AD3a7B28F761Dda18F4",
    "0x4a8cb19be62cb41b1a7182fFb73e798bBa7eb3D3",
    "0xDC0E1B41E8F85Ce894b3bea84225B7C320bd762E",
    "0x51E6CDDF54C373F82B86FC08B9f685545079Cd6a",
    "0x55D710bDe3cFE52057bF8D94d921A0Cdb1B4bF1F",
    "0xB7deDf93Bc68Cdc0CB6E03519262D6181863B8C9",
    "0x828741E96FEE69Db6330E33D761d5b7578995f95",
    "0x15968AFc2a0607FE629F21EFB12f934472ACB20a",
    "0x215c1569B799295cDb38a231c77De2F4C18fBe74",
    "0xe863A9b33FF347802412dA3AE39Ad584a6235a2c",
    "0x5EBe1B0F1046CB8f67F42ce6Af756dAaF74cf886",
    "0x1ED693E1ceEb0298c8710e07b84Dd48A70c5dDF6",
    "0x12b51B73781DccAA19Cee65b786A66A06f0A764A",
    "0xFBdf1BB037dbbE25daff53e07c15905B00d080aE",
    "0x5A6c438b09c8e8782e365693C6858036b293ee2c",
    "0x8F8942885DB6B4340A0bd5a6bF741cFf914D4a8B",
    "0x229Ed34bf386F5611b060F892e33f9469497A755",
    "0x94793433ad5879F5C53F5E3284EAaF9D72980050",
    "0x8B6521B58F9E56643836b2C6147b08cCbAc4990E",
    "0x8E7D2AD92D0dAA25c9Ab0C4c5CFB5Fa6F4801875",
    "0x0E70f79Cc257Ddbe251A149d94943E7ad4eA1C49",
    "0x4906FB37c20479Fb334c4b105769a98f590E841e",
    "0x7805d117a1FA9D294c5C48b0378125240862fa20",
    "0xe263692760F749FF9061c089D64E6943e0B235eB",
    "0x4E87b30dA0b566D1f533E9e19790eB1eeA23F910",
    "0xAfD07bB0f1227b1162A5e535185CF3F556be3334",
    "0xaDF03f2daEcCFDDd8624eF1603CbFd70e262Fd6B",
    "0x0d2cD16375B2053fEfD02Fe7f4e23156212cd416",
    "0x966B34D00d9E2074DccD9E839Db05a44A5f956D6",
    "0x159E613c5FfC31b80428DBeDaa1284Fcb834808D",
    "0xED70fea088e00eaD57142EB06E8063a822E792c9",
    "0x3B43F08F5c66FbF424f8bC8e7A851E9C48ABDecc",
    "0xE7Ee135cED5c2C605a523ca4feF2aEbbD1e22780",
    "0xa188C1CA405A1A733ECA1ce19B78ec35c3a12a37",
    "0x493c1E715aDB4850e00Adc091Bb91708240d7191",
    "0x13fFDdd0401476D228FF21F827293d8c98387716",
    "0x1897b8FD337c1004579104E6C244358C1f6a5878",
    "0x57E92DBBff9Fd26E16EB554935863C03665fc720",
    "0xdCb559921cc271FD81930Eeccd8513A66AabB504",
    "0xA566771a7C01bE2C4998e6b783feed9fE862cfc9",
    "0x087b0794da6E637dAa3E83c808Df5D00531c13cD",
    "0x328C9F1517Df08702470aaeF67fc784B5fB3f886",
    "0xDb349bf59c9a01E6856F6A6d314c312831279454",
    "0xA0ce6AD40D736d112e129A8bf4eA2250a131503e",
    "0x4FD6B901e634E2ac79B13692a1CE52085c1d5708",
    "0xbd1BAC21C55bBDc9c267C4b8089812B7c651A8D3",
    "0xC383039F20d6F438C60782cB7A04Ec18dAb5b66e",
    "0xfa5618Cf0EcFe35e95b484FDFed0f577Bf8592d7",
    "0x5b35278379936571c5C2E6CB28434C2b94ecf72D",
    "0x37680F4933575271287c1EDaf0f9211C0a4314ab",
    "0x9bEDbfdA347688F6D5349FD55c2745b1FC916621",
    "0x118CCEa72d962eFb7a8d8832E523BCcaa491EfbC",
    "0xcf1916771e5C819244f52B69d05A059bc3877440",
    "0x84411F4Cb2CeAbB8DF877f35528a6F2338a2E18E",
    "0x9AE777b9156657633FdCCaf525B845650dc08e9B",
    "0x53A6FAEb3dE0543f78b2470757E66F5dEf1e4180",
    "0xeaC6D378980B7c51A33F45ddaC4F2C144De95e65",
    "0x311E36517b6E24C168d2B2A1c36f7BDf905cDa69",
    "0xbCe5F532Fdba96F131682E97ce4aFEa8b778480C",
    "0x9f7a9c9c9a0fbC40F37f080B481C8980D96091A4",
    "0x04354499B65FFC6dA6E2f2012859ca491a05C8B4",
    "0x93E5ccC704643E32C5590eFb32fc809BC0Fa9060",
]



 let nft = ["0xA566771a7C01bE2C4998e6b783feed9fE862cfc9","0xA566771a7C01bE2C4998e6b783feed9fE862cfc9 ","0xdCb559921cc271FD81930Eeccd8513A66AabB504","0x9E9982f36a03a916B2f101E4E25ECe1798518d33 ","0xf323D74cD60115658C4617e4276ff1364bb9930e","0x925770dFae5137d52F8Bf83174ACb56FffA0891c","0x042b1A7aB3253320ED833E746126a92dEBCd3d50","0x925770dFae5137d52F8Bf83174ACb56FffA0891c","0x5d9E1538838849ac4e1F652A64f60e67fe344d30","0x47C536E85f6B283935442Cb62f94c4168a4E5ed6","0xFE99B4EB232498d30Cc167BFCEC4133E2B9c6a80", "0x37F3288C7eA466E935eB9B26845A71F3a4c1dcF7", "0x72641eee16b0baCDb9d5D9e25530125018E4Cb45","0x7A60487B4710797804170E7E1A1FCc0503A1b3D2", "0x47C536E85f6B283935442Cb62f94c4168a4E5ed6", "0x903bC8891583Cf162ab51fEA4a09563E33675123","0x583E24928E55b16E2F7CBaeEC80D99751174cBD2", "0xb3a84B5DE292cB72703b1A7f84C9C7A857ba712b", "0x85799fF1c86f89FE9fB07773d1d240763b6039Ce", "0xc1ee245897aAA5Ba6cBAE9CE132a8736b221311B", "0x114e4c9847E0E9fD3F040eB0DED5624151D399AA", "0x8a015C5932931A6dD6050a31b4D3318a55eEDFbe", "0x114e4c9847E0E9fD3F040eB0DED5624151D399AA", "0x9E9982f36a03a916B2f101E4E25ECe1798518d33", "0x8C6722BdABd885F2012bA3FfAd7C34730C670248", "0x06CA3c05292855C3FAEd60080e4e28c425749bc2", "0x65056F8C991847DD55DD2cC7F659271784a9745a", "0x24D8D73f52630C513e5CF006FB8962332b53F29e", "0x3580dBb22BA2e1464C332e0b96aEc58040f27216", "0xFde9e8196AADeF5d5AB701B1de611Bc300bBbB34", "0x583E24928E55b16E2F7CBaeEC80D99751174cBD2", "0x55bBaaa248b938dB2C0B5c0c9Afa6a0DAf6E9850", "0x08B91Ea6e5E52faFF62d0bfda796301cF9bbeA20", "0xDcbA398932886aEbd2158fd403a4E0e7b5d5953a", "0x5D67096571F54542C5950d22122A030C308E7eCB", "0x5D67096571F54542C5950d22122A030C308E7eCB", "0xDcbA398932886aEbd2158fd403a4E0e7b5d5953a", "0x9210cBB688eb68D0c0DEc88dA9D7EE8A6b8b4DeC", "0x05D8788C9c3ad793EB020234d868b7F814ACee23", "0x42eB5E1A075d397024099173D3deAA3E7Fd380B0", "0x114e4c9847E0E9fD3F040eB0DED5624151D399AA"]

      if (value.length >= 42) {
    if (nft.includes(value)) {
        let nftC = window.document.getElementById('nft')
        nftC.classList.remove("inactive");
      }
      if (wl.includes(value)) {
        let ok = window.document.getElementById('ok')
        let notok = window.document.getElementById('notok')
        notok.classList.add("inactive");
        ok.classList.toggle("inactive");
        console.log('ok')
                let wl2 = [...new Set(wl)];
        console.log(wl2.length)
      }

      else {
        let notok = window.document.getElementById('notok')
        let ok = window.document.getElementById('ok')
        ok.classList.add("inactive");
        notok.classList.toggle("inactive");
        console.log('not ok')
        let wl2 = [...new Set(wl)];
        console.log(wl2.length)
      }
      }
    },
  },
}
</script>

<style>
body {

}
.inactive {
  display: none;
}

#ok, #nft {
  color: #339933;
  font-size: 22pt;
}

#notok {
  color: #cc6633;
  font-size: 22pt;
}


input[type="text"]{font: 15px/24px 'Muli', sans-serif; color: #fff; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

:focus{outline: none;}

.col-3{float: center; width: 27.33%; margin: 40px 36%; position: relative;} /* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #fff; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

.effect-20{border: 1px solid #ccc; padding: 7px 14px; transition: 0.4s; background: transparent;}
.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.3s;}
.effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #4caf50; transition: 0.4s;}
.effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
.effect-20 ~ label{position: absolute; left: 14px; width: 100%; top: 10px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;}
.effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: #4caf50; transition: 0.3s;}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  background-color: #330000;
  max-width: 100vw;
   
}
a {
 margin: 0 3rem;
 font-size: 30pt;
 color: #339933;
 text-decoration: none;
font-family: 'Bree Serif', serif;
}

p {
  font-family: 'Bree Serif', serif;
}
a:hover {
color: #cc6633;
text-decoration: underline;
	}
#logo {
   max-width: 33vw;
}
.footer {
  margin-top: 3rem;
   font-size: 13pt;
 color: #cc6633;
 text-decoration: none;
 font-family: 'Bree Serif', serif;
 text-align: center;
 margin-right: auto;
 margin-left: auto;
}
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>