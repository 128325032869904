<template>
  <div id="main">
    <a href="https://www.glmrjungle.com"><img id="logo" alt="logo" src="../assets/logo.png"></a>
                    <h1><p class="mintHeader">Bananas Mint 🍌</p></h1>

                   <div style="margin: 3rem;">  <btn id="connectBtn" v-on:click="connect()">Connect Wallet</btn></div>
      <div id="explain">
        <div id="stats">
          <h1>🌴</h1>
        <h2 id="totalSupply"></h2>
        <h2 id="tokensOwned"></h2>
        </div>
        <p id="">
          You can buy <b>1 NFT for 35 $BANANAS.</b><br>
          You can mint <b>5 NFTs maximum per transaction</b><br>
          You can mint <b>as many NFTs as you want during the Bananas Mint</b><br>
          The Bananas Mint will be open until <b>April 2nd</b><br>
          If you need more Bananas, please visit <a style="font-size: 100%; padding: 0; margin: 0;" href="https://bananaswap.app" target="_blank">BANANASWAP</a> 🍌
        </p>
        </div>
       
        <div id="mintForm">
        <input
      id="mintNb"
      class="mx-3"
      max="5"
      min="1"
      placeholder="1"
      type="number"
      >
      <btn id="mintBtn" v-on:click="mint()">Mint!</btn>
      </div>
      

               
                        
                        <div style="display: inline-flex;">
                       <a class="links px" href="https://www.bananaswap.app" target="_blank">DEX</a>
                       <a class="links px" href="https://twitter.com/GLMRApes" target="_blank">Twitter</a>
                       <a class="links px" href="https://discord.gg/H9DH3z2bTV" target="_blank">Discord</a>
                       <a class="links px" href="https://t.me/+qGh0InPSPc1iMTNk"  target="_blank">Telegram</a>
                       </div>
    

                         <p class="footer">
                    GLMR APES DAO
                    </p>

  </div>
  
</template>

<script>
import { ethers } from 'ethers'
import { glmjAddress, glmjABI, bananasAddress, bananasABI} from './conf.js'

export default {
  name: 'BananasMint',
  data() {
    return {
      wallet: '',
      isActive: false
    }
  },
    watch: {
  wallet(value){
      // binding this to the data value in the email input
      this.wallet = value;
      this.checker(value);
    }
  },
  components: {
  
  },
  methods: {
    async mint() {
      
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const bananasContract = new ethers.Contract(bananasAddress, bananasABI, signer); 
      //console.log(signerAddress, bananasContract)
      let balance = window.document.getElementById('mintNb').value
 let tokenB = balance
      if (balance == 0) {
        balance = 1
      }
      if (balance > 5) {
        alert('Max mint is 5 NFTs per transaction')
      }
       var numberOfDecimals = 18;
       
  //let ticketsNB = 5.0 * spNb;
      let finalPrice = balance * 35
     
     let balance2 = ethers.utils.parseUnits(finalPrice.toString(), numberOfDecimals)
     //console.log(balance2)
      let approve = await bananasContract.approve(glmjAddress, balance2)
  
      const tx = await approve.wait()
      const event = tx.events[0];
       alert(`Please confirm next transaction`)
      let glmjContract = new ethers.Contract(glmjAddress, glmjABI, signer);

      const action = await glmjContract.bananasMint(signerAddress, `${balance}`)
           const tx2 = await action.wait()
      const event2 = tx2.events[0];
   //console.log(event, event2, action)
      alert(`${tokenB} GLMR JUNGLE successfully Minted !`)
      window.location.reload();
    },

        async pmint() {
      
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const bananasContract = new ethers.Contract(bananasAddress, bananasABI, signer); 
      //console.log(signerAddress, bananasContract)
      let balance = window.document.getElementById('mintNb').value
 let tokenB = balance
      if (balance == 0) {
        balance = 1
      }
      if (balance > 5) {
        alert('Max mint is 5 NFTs per transaction')
      }

       
  //let ticketsNB = 5.0 * spNb;

      let glmjContract = new ethers.Contract(glmjAddress, glmjABI, signer);
let finalPrice = balance * 30

      const action = await glmjContract.publicMint(signerAddress, `${balance}`,{  value: ethers.utils.parseEther(finalPrice.toString()) })
           const tx2 = await action.wait()
      const event2 = tx2.events[0];
   //console.log(event, event2, action)
      alert(`${tokenB} GLMR JUNGLE successfully Minted !`)
      window.location.reload();
    },

    async connect() {

       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const bananasContract = new ethers.Contract(bananasAddress, bananasABI, signer);
      const glmjContract = new ethers.Contract(glmjAddress, glmjABI, signer);
      let supply = await glmjContract.totalSupply()
      supply = supply.toNumber();
      let owned = await glmjContract.walletOfOwner(signerAddress)
     let owned3 = owned.length
      console.log(glmjAddress, glmjABI, bananasContract, supply)
      await alert(`${signerAddress} connected`)

       let explain2 = window.document.getElementById('explain')
       let mintForm2 = window.document.getElementById('mintForm')
        let connect2 = window.document.getElementById('connectBtn')
        let supply2 = window.document.getElementById('totalSupply')
        let owned2 = window.document.getElementById('tokensOwned')
       explain2.style.display = "block";
       mintForm2.style.display = "block";
      connect2.style.display = "none";
      supply2.innerText = `${supply} / 3333`
      owned2.innerText = `${owned3} 🐵 Minted`
 
    },
   
  },
}
</script>

<style>
#main {
  max-height: 100% !important;
}

html {
   overflow-y: scroll;
   
}



html, body {
  margin:0;
  padding:0;
  height:100%;
   

}

body {
  font-family: 'Bree Serif', serif;
}

#stats {
  margin: 2rem 0;
}

#mintNb {
  min-height: 25px;
  min-width: 55px;
  max-width: 50px;
  margin-top: 1rem;
}

#totalSupply {
  margin: 1rem 0;
}

#tokensOwned {
  margin: 1rem 0;
}

#mintBtn {
  margin-left: 2rem;
  padding: 5px 15px;
  color: white;
  font-family: 'Bree Serif', serif;
  font-size: 150%;
  background-color: #339933;
  cursor: grab;
}

#connectBtn {
  height: 350px;
  width:  400px;
  margin:  4rem;
  padding: 5px 15px;
  color: white;
  font-family: 'Bree Serif', serif;
  font-size: 150%;
  background-color: #339933;
  border-radius: 3%;
  cursor: grab;
}

input, select, textarea{
    color: #339933;
    font-family: 'Bree Serif', serif;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    font-size: 15pt;
}
#mintForm {
  margin: 1.3rem 0 3rem;
  display: none;
}


.mintHeader {
  color: #F7CA18;
  margin: 0;
  padding: 0;
}

#explain {
  color: white !important;
  padding-top: 0;
  margin-top: 0;
  font-size: 16pt;
  display: none;
}

body {

}
.inactive {
  display: none;
}


#logo {
  max-height: 400px;
  max-width: auto;
}
@media only screen and (max-width: 600px) {
.links {
  font-size: 18pt !important;
  margin: 1rem;
}
#logo {
max-width: 70vw;
display: none;
}
#connectBtn {
    height: 350px;
  width:  400px;
  margin:  0;
  padding: 0.8rem 0.9rem;
}
}

.links {
   font-size: 30pt;

}
a {
 margin: 0 3rem;
 color: #339933;
 text-decoration: none;
font-family: 'Bree Serif', serif;
}

p {
  font-family: 'Bree Serif', serif;
}
a:hover {
color: #cc6633;
text-decoration: underline;
	}

.footer {
  margin-top: 3rem;
   font-size: 13pt;
 color: #cc6633;
 text-decoration: none;
 font-family: 'Bree Serif', serif;
 text-align: center;
 margin-right: auto;
 margin-left: auto;
}
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>