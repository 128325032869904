
<script>
import { ethers } from 'ethers'
import { db } from '../main.js'
import { glmjAddress, glmjABI, bananasAddress, bananasABI } from './conf.js'
export default {
  data() {
    return {
      isLoading: false,
      walletAddress: '',
      isConnect: false,
      token: '',
      tokensOwned: '',
      chainID: '',
      provider: {},
      signer: {},
      blockNumber: {},
      gasPrice: {},
      overrides: {},
      w: {},
      ids: [],
      tokendetail: {},
      images: [],
      images1: [],
    }
  },
  methods: {

  },
  mounted() {


    //console.log(elements)

    if (window.ethereum) {
      if (this.signer) {
        this.isConnect = true;
        //this.connect()
      }
    }
  },
}
</script>

<style>
.navbar {
  background-color: transparent;
  margin: auto;
}

.nav-link {
  vertical-align: middle !important;
  margin: auto 2rem;
}

.nav-link:hover {
  font-weight: bold;
  color: #f7ca18 !important;
  background-size: 400%;
}

a {
  font-size: 2rem !important;
}
</style>

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent" style="">
    <a class="navbar-brand" href="./">
      <img src="/assets/logo.png" width="256" height="256" class="d-inline-block align-top" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <a class="nav-item nav-link" href="./jungle">My Jungle</a>
      <a class="nav-item nav-link" href="https://www.playtge.com">The Great Escape</a>
      <a class="nav-item nav-link" href="./gallery">Our Gallery</a>
      <a class="nav-item nav-link" href="https://glmrapes.notion.site/">FAQ</a>
      <a class="nav-item nav-link" href="https://www.bananaswap.app">Buy Bananas</a>
      <a class="nav-item nav-link y" href="staking"><b>Unstake</b></a>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link" href="./jungle">My Jungle</a>
        <a class="nav-item nav-link" href="https://www.playtge.com">The Great Escape</a>
        <a class="nav-item nav-link" href="./gallery">Our Gallery</a>
        <a class="nav-item nav-link" href="https://glmrapes.notion.site/">FAQ</a>
        <a class="nav-item nav-link" href="https://www.bananaswap.app">Buy Bananas</a>
        <a class="nav-item nav-link y" href="staking"><b>Unstake</b></a>

      </div>
    </div>
  </nav>
</template>
