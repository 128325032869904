<template>
  <div id="app">
<Menu/>
<!-- <div style="margin: 3rem;">  <btn id="connectBtn" v-on:click="connect()">Connect Wallet</btn></div> -->

  <b-container>
                  <h1 class="g h1 knockout" style="font-size: 60pt !important;">MINOR BADGES</h1>
                   <h2 class="w">NOVEMBER SALES</h2>
                    <!-- <b-spinner v-if="isLoading" variant="warning" class="mt-4 ml-4 text-center" id="loading" label="Busy"></b-spinner> -->
            <div class="zoom py-3"><btn id="connectBtn" class="zoom" v-on:click="connect()">Connect Wallet</btn></div>
      <div id="explain">
        <b-container>
        <b-row>
        <p class="w" style="text-align: center; margin: auto;">

Equip your Apes with a 2nd badge to get an additional boost of their in-game performance.</p>
<b-row>
<b-col>
<ul  class="mx-4 px-4 py-4">
  <li class="py-2">
<b class="h3 y">🍍 Fruit Taster <span class="g" style="font-size: 85%;">(x250)</span></b><br>Eat up to 3 rotten fruits without loosing score points.</li>
  <li class="py-2">
  <b class="h3 y">🅰 The Letter <span class="g" style="font-size: 85%;">(x200)</span></b><br>Doubles the points you get for each A,P,E,S letter that you pick in the game.
</li>
  <li class="py-2">
<b class="h3 y">🥥 Coco Helmet <span class="g" style="font-size: 85%;">(x175)</span></b><br>Start the game with a Coco Helmet equiped for 15 seconds. It allows you to take enemy hits without losing any HP.
</li>
</ul>
 </b-col>

 <b-col>
<ul  class="mx-4 px-4 py-4">
  <li class="py-2">
<b class="h3 y">🧲 Juicy Attraction <span class="g" style="font-size: 85%;">(x150)</span></b><br>Start the game with a Magnet. It will automatically attract the fruits towards you during 15 seconds.
</li>
  <li class="py-2">
<b class="h3 y">⏳ Master of Time <span class="g" style="font-size: 85%;">(x100)</span></b><br>Get an additional +10 seconds for each game session.
</li>
  <li class="py-2">
<b class="h3 y">😡 Ape Wrath <span class="g" style="font-size: 85%;">(x75)</span></b><br>Doubles the score points you receive for each ennemy killed.
    </li>
    </ul>
  </b-col> 
</b-row>

      </b-row>
      <b-row class="mx-auto pt-4">

     
                          <b-col>
                        <b-button type="button" class="px h4 checker" variant="outline-light py-2 mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(1)"><strong class="i">Fruit Taster</strong></b-button>
                           </b-col>
                          <b-col>
                        <b-button type="button" class="px h4 checker" variant="outline-light py-2  mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(2)"><strong class="i">The Letter</strong></b-button>
                          </b-col>
                           <b-col>
                        <b-button type="button" class="px h4 checker" variant="outline-light py-2  mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(3)"><strong class="i">Coco Helmet</strong></b-button>
                          </b-col>
                          <b-col>
                        <b-button type="button" class="px h4 checker" variant="outline-light py-2  mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(4)"><strong class="i">Juicy Attraction</strong></b-button>
                          </b-col>
               
                          <b-col>
                        <b-button type="button" class="px h4 checker" variant="outline-light py-2 mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(5)"><strong class="i">Master of Time</strong></b-button>
                          </b-col>
                          <b-col>
                        <b-button type="button" class="px h4 checker" disabled variant="outline-light py-2 mx-2" size="md" v-if="isConnect" v-on:click="speciesFilter(6)"><strong class="i">Ape Wrath</strong></b-button>
                          </b-col>

        </b-row>
        </b-container>

        </div>

        <b-row cols="3" class="" v-if="!isLoading">
          <b-col class="mx-auto mx-2 py-4" fluid v-for="(item, index) in images" :key="index" transition="staggered" stagger="250">
          <!--<p class="w font-weight-bold" style="font-size:120%;"><u>{{images[index].species}}</u></p>-->
          <b-img-lazy class="apes zoom" v-bind:src="images[index].url" style="max-width: 85%;"></b-img-lazy>
                    <p class="h2 y py-2 font-weight-bold px"><span class="glmr">JUNGLE</span><span class="catchy"> BADGE</span><br><span class="px font-weight-bold w">#{{images[index].id}}</span></p>
        
          <p class="g h3 py-2">{{images[index].price / 1000000000000000000}} BANANAS (GLMB)</p>
           <div class="inline pt-1 zoom">
          <b-button type="button" class="px h4 p-3" v-if="approved" variant="outline-light mx-2" size="md" v-b-modal.modal v-on:click="buy(images[index].id, images[index].price )"><strong class="i h4">Buy this badge</strong></b-button>
              
              <b-button type="button" class="px h4 checker2 zoom" variant="outline-light mx-2" size="md" v-if="!approved" v-b-modal.modal v-on:click="approve()"><strong class="i zoom">Approve Contract</strong></b-button>
          </div>
          </b-col>
        </b-row>




    </b-container>
  <b-row>
                    <p class="footer">
                    GLMR APES DAO
                    </p>
                      </b-row>
  </div>
</template>

<style>
ul {
  list-style-type: none;
}
.checker {
  height: 80px;
}
.checker2 {
  height: 70px;
}
</style>
<script>
import Menu from './Menu.vue'
import { ethers } from 'ethers'
var utils = require('ethers').utils;
import { db } from '../main.js'
import { glmjAddress, glmjABI, bananasAddress, bananasABI, jptsAddress, jptsABI, badgesAddress, badgesABI, badgesMarketAddress, mBadgesMarketAddress2, badgesMarketABI, badgesMarketABI2} from './conf.js'
//const bMarketAddress = "0xB0B20704d6f81B74aA3B797BDab81B6cB4B82Af5"
const bMarketAddress = "0xeFC9fb8442A039780138bc87397a6288431d0fe7"
export default {
  name: 'Badges',
  components: {
  Menu
  },
  data() {
 return {
images: [],
isConnect: false,
isLoading: false,
approved: false,
tokendetail: {},
marketOwned: [],
    };
  },
  mounted() {
  },
  methods: {

        async connect() {
        this.isLoading = true
       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const glmbContract = new ethers.Contract(bananasAddress, bananasABI, signer)
      const badgesContract = new ethers.Contract(badgesAddress, badgesABI, signer)
      let supply = await glmbContract.totalSupply()
      let owned = await badgesContract.walletOfOwner(bMarketAddress)
      this.marketOwned = owned

            let allowance = await glmbContract.allowance(signerAddress, bMarketAddress)
            let allowance2= utils.formatUnits(allowance)
            await alert(`${signerAddress} connected`)
                          if (allowance2 < 222) {
                            this.approved = false
                          }
                          else {
                            this.approved = true
                          }
                        
                  let explain2 = window.document.getElementById('explain')
                  let connect2 = window.document.getElementById('connectBtn')
                    //let owned2 = window.document.getElementById('tokensOwned')
                await this.loadTokens()
                explain2.style.display = "flex";
                connect2.style.display = "none";
                this.isConnect = true;
                this.isLoading = false
                
      
    },
    

    async buy(id, price) {
      let abi = [{"anonymous":false,"inputs":[{"indexed":false,"internalType":"uint256","name":"ad","type":"uint256"},{"indexed":false,"internalType":"bytes32","name":"status","type":"bytes32"}],"name":"TradeStatusChange","type":"event"},{"inputs":[{"internalType":"uint256","name":"_trade","type":"uint256"}],"name":"cancelTrade","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"uint256","name":"_trade","type":"uint256"}],"name":"executeTrade","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"uint256","name":"_item","type":"uint256"},{"internalType":"uint256","name":"_price","type":"uint256"}],"name":"openTrade","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"_currencyTokenAddress","type":"address"},{"internalType":"address","name":"_itemTokenAddress","type":"address"}],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"uint256","name":"_trade","type":"uint256"}],"name":"getTrade","outputs":[{"internalType":"address","name":"","type":"address"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"bytes32","name":"","type":"bytes32"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"uint256","name":"","type":"uint256"}],"name":"trades","outputs":[{"internalType":"address","name":"poster","type":"address"},{"internalType":"uint256","name":"item","type":"uint256"},{"internalType":"uint256","name":"price","type":"uint256"},{"internalType":"bytes32","name":"status","type":"bytes32"}],"stateMutability":"view","type":"function"}]
       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      console.log(id,price)
      const marketContract = new ethers.Contract(bMarketAddress, abi, signer)
const mapping = [{id:370,saleId:0},{id:371,saleId:1},{id:372,saleId:2},{id:373,saleId:3},{id:374,saleId:4},{id:378,saleId:5},{id:381,saleId:6},{id:383,saleId:7},{id:384,saleId:8},{id:386,saleId:9},{id:387,saleId:10},{id:388,saleId:11},{id:627,saleId:12},{id:628,saleId:13},{id:629,saleId:14},{id:630,saleId:15},{id:673,saleId:16},{id:674,saleId:17},{id:675,saleId:18},{id:676,saleId:19},{id:677,saleId:20},{id:678,saleId:21},{id:833,saleId:22},{id:834,saleId:23},{id:835,saleId:24},{id:836,saleId:25},{id:837,saleId:26},{id:838,saleId:27},{id:839,saleId:28},{id:840,saleId:29},{id:906,saleId:30},{id:1049,saleId:31},{id:1050,saleId:32},{id:1051,saleId:33},{id:1052,saleId:34},{id:1053,saleId:35},{id:1054,saleId:36},{id:1056,saleId:37},{id:1057,saleId:38},{id:1134,saleId:39},{id:1135,saleId:40},{id:1136,saleId:41},{id:1138,saleId:42},{id:1143,saleId:43}]
let fSaleId = mapping.find(element => element.id == id).saleId
      console.log(fSaleId)
              try {
      price = price / 1000000000000000000
      let execute = await marketContract.executeTrade(fSaleId)
      await execute.wait()
      alert('JUNGLE Badge successfully claimed!')
      window.location.reload()
      }
      catch(err) {
        this.isLoading = false
      }
      
    },
    async approve() {
 const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const glmbContract = new ethers.Contract(bananasAddress, bananasABI, signer)
     let approval= await glmbContract.approve(bMarketAddress, "500000000000000000000000")
     await approval.wait()
     alert('Minor Badges contract successfully approved!')
     window.location.reload()
    },
    async loadTokens() {
            let result2 = []
      if (this.marketOwned.length > 0) {
          this.marketOwned.forEach(element => {
        result2.push(parseFloat(element))
        }
        );
       let galleryLinks = []
      if (result2.length > 0) {
     
     for (let g = 0; g < result2.length; g++) {
             if (result2[g] >= 285 && result2[g] < 536) {
      galleryLinks.push({id:`${result2[g]}`, species:'Fruit Taster', price:41000000000000000000, bio:'Eat up to 3 rotten fruits without loosing score points.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/FRUIT%20TASTER.png`})
       }
        if (result2[g] >= 536 && result2[g] < 735) {
      galleryLinks.push({id:`${result2[g]}`, species:'The Letter', price:67000000000000000000, bio:'Provides additional extra time for your game session.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/THE%20LETTER.png`})
       }
       if (result2[g] >= 735 && result2[g] < 910 ) {
      galleryLinks.push({id:`${result2[g]}`, species:'Coco Helmet', price:95000000000000000000, bio:'Provides additional extra time for your game session.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/COCO%20HELMET.png`})
       }
       if (result2[g] >= 910 && result2[g] < 1060) {
      galleryLinks.push({id:`${result2[g]}`, species:'Juicy Attraction', price:121000000000000000000, bio:'Provides additional extra time for your game session.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/JUICY%20ATTRACTION.png`})
       }
       if (result2[g] >= 1060 && result2[g] < 1160) {
      galleryLinks.push({id:`${result2[g]}`, species:'Master of Time', price:186000000000000000000, bio:'Provides additional extra time for your game session.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/MASTER%20OF%20TIME.png`})
       }
       if (result2[g] >= 1160) {
      galleryLinks.push({id:`${result2[g]}`, species:'Ape Wrath', price:99000000000000000000, bio:'Doubles the score points you receive for each enemy killed.', url:`https://gateway.ipfs.io/ipfs/bafybeiguu7h6ydlhe72cpvfrjdektbmfvc5db43jeegpvpxufrsqhafkcq/APE%20WRATH.png`})
       }
       
     }
     
         this.images = galleryLinks.sort((a, b) => a.id - b.id);
      }
      }
    },
  async speciesFilter(id) {
    await this.loadTokens()
    let tArray = []
if (id == 1) {
        for( let i = 0; i < this.images.length; i++){ 
    
        if ( this.images[i].id >= 285 && this.images[i].id < 536 ) { 
            tArray.push(this.images[i]) 
        }
    
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
if (id == 2) {
        for( let g = 0; g < this.images.length; g++){ 
        if ( this.images[g].id >= 536 && this.images[g].id < 735) { 
            tArray.push(this.images[g]) 
        }
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
if (id == 3) {
        for( let g = 0; g < this.images.length; g++){ 
        if ( this.images[g].id >= 735 && this.images[g].id < 910) { 
            tArray.push(this.images[g]) 
        }
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
if (id == 4) {
        for( let g = 0; g < this.images.length; g++){ 
    if ( this.images[g].id >= 910 && this.images[g].id < 1060) { 
            tArray.push(this.images[g]) 
        }
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
if (id == 5) {
        for( let g = 0; g < this.images.length; g++){ 
    if ( this.images[g].id >= 1060 && this.images[g].id < 1160) { 
            tArray.push(this.images[g]) 
        }
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
if (id == 6) {
        for( let g = 0; g < this.images.length; g++){ 
        if ( this.images[g].id >= 1160 ) { 
            tArray.push(this.images[g]) 
        }
    }
    this.images = tArray.sort((a, b) => a.id - b.id);
}
  },
  async checkAllowance(signer) {
  }
   
  }
}
</script>

<style>
@import './style.css';
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>