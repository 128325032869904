import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Whitelist from '../views/Whitelist.vue'
import Iwanttoapein from '../views/Iwanttoapein.vue'
import BananasMint from '../views/BananasMint.vue'
import WlMint from '../views/WlMint.vue'
import PublicMint from '../views/PublicMint.vue'
import LpWithdraw from '../views/LPWithdraw.vue'
import Jungle from '../views/Jungle.vue'
import Gallery from '../views/Gallery.vue'
import Staking from '../views/Staking.vue'
import Discord from '../views/Discord.vue'
import Badges from '../views/Badges.vue'
import Sales from '../views/Sales.vue'
import MinorBadges from '../views/MinorBadges.vue'
import Reset from '../views/Reset.vue'
import Multisend from '../views/Multisend.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/whitelist',
    name: 'Whitelist',
    component: Whitelist
  },
  {
    path: '/minorbadges',
    name: 'Minor Badges',
    component: MinorBadges
  },
  {
    path: '/iwanttoapein',
    name: 'Iwanttoapein',
    component: Iwanttoapein
  },
  {
    path: '/bananasmint',
    name: 'Bananas Mint',
    component: BananasMint
  },
  {
    path: '/wlmint',
    name: 'WhiteList Mint',
    component: WlMint
  },
  {
    path: '/mint',
    name: 'Public Mint',
    component: PublicMint
  },
  {
    path: '/lpwithdraw',
    name: 'Emergency LP Withdraw',
    component: LpWithdraw
  },
  {
    path: '/jungle',
    name: 'My Jungle',
    component: Jungle
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/staking',
    name: 'Staking',
    component: Staking
  },
  {
    path: '/discord',
    name: 'Discord',
    component: Discord
  },
  {
    path: '/badges',
    name: 'Badges',
    component: Badges
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/multisend',
    name: 'Multisend',
    component: Multisend
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
