
<script>
import { ethers } from 'ethers'
import { db } from '../main.js'
import { glmjAddress, glmjABI, bananasAddress, bananasABI} from './conf.js'
export default {
  data() {
    return {
      isLoading: false,
        walletAddress: '',
        isConnect: false,
      token: '',
      tokensOwned : '',
      chainID: '',
            provider: {},
      signer: {},
      blockNumber: {},
      gasPrice: {},
      overrides: {},
      w: {},
    ids : [],
    tokendetail : {},
      images : [],
      images1 : [],
    }
  },
  methods: {
             
  },
  mounted() {


//console.log(elements)

  if (window.ethereum) {
  if (this.signer) {
      this.isConnect = true;
      //this.connect()
  }
}
},
}
</script>

<style>
#footer {
  min-width: 200px;
  width: 100vw;
  padding: 0;
}
</style>

<template>
<b-container id="footer">
<b-row class="mx-2 pt-4">

                   <b-col class="" style="">
                    <p class="p-0 m-0 h3">&#x1F4CA;</p>
   <a class="text-align-center" href="https://www.bananaswap.app" target="_blank">DEX</a>

                     </b-col>
                   <b-col class="" style="">
      <p class="p-0 m-0 h3">&#x1F3AE;</p>
   <a class="text-align-center" href="https://game.glmrjungle.com" target="_blank">Game</a>

                     </b-col>

              <b-col class="" style="">
          <p class="p-0 m-0 h3">&#x1F4AC;</p>
      <a class="text-align-center" href="https://twitter.com/GLMRJungle" target="_blank">Twitter</a>
                     </b-col>

         <b-col class="" style="">
              <p class="p-0 m-0 h3">&#x1F4E2;</p>
               <a class="text-align-center" href="https://discord.gg/H9DH3z2bTV" target="_blank">Discord</a>
                     </b-col>
                  
                               <b-col class="" style="">
                                        <p class="p-0 m-0 h3">&#x2709;&#xFE0F;</p>
<a class="text-align-center" href="https://t.me/+qGh0InPSPc1iMTNk"  target="_blank">Telegram</a>
                     </b-col>
                       
  </b-row>
  <b-row>
                    <p class="footer h4">
                      <span class="w">MADE WITH	&#x1F34C; BY</span> </p></b-row>
                      <b-row>
                    <a class="mx-auto" href="https://glmrapes.com"><img src="/logoglma.png" height="250" width="250" class="my-2 mx-auto"></a>
           
                      </b-row>
             
                      </b-container>
 </template>
