<template>
  <div id="main">
    <a href="https://www.glmrjungle.com"><img id="logo" alt="logo" src="../assets/logo.png"></a>
                    <h1><p class="mintHeader">OLD POOOL FIX 🍌</p></h1>

                   <div style="margin: 3rem;">  <btn id="connectBtn" v-on:click="connect()">Connect Wallet</btn></div>

       <div id="explain">
        <div id="mintForm">
                              <input
      id="lpStaked"
      class="mx-3"
      placeholder="1"
      type="number"
      >
      <btn id="mintBtn" v-on:click="withdraw()">Withdraw Inactive LP</btn>
      <p style="margin-top: 1rem;">Inactive LP: <span id="inactive"></span></p>
      <btn id="emergency" style="color: red;" v-on:click="emergency()">Emergency Withdraw</btn>
      </div>
</div>      

               
                        
                        <div style="display: inline-flex;">
                       <a class="links px" href="https://www.bananaswap.app" target="_blank">DEX</a>
                       <a class="links px" href="https://twitter.com/GLMRApes" target="_blank">Twitter</a>
                       <a class="links px" href="https://discord.gg/H9DH3z2bTV" target="_blank">Discord</a>
                       <a class="links px" href="https://t.me/+qGh0InPSPc1iMTNk"  target="_blank">Telegram</a>
                       </div>
    

                         <p class="footer">
                    GLMR APES DAO
                    </p>

  </div>
  
</template>

<script>

import { ethers } from 'ethers'
import { glmjAddress, glmjABI, bananasAddress, bananasABI, farmAddress, oldfarmAddress, farmABI, newfarmABI} from './conf.js'

export default {
  name: 'LP WITHDRAW',
  data() {
    return {
      wallet: '',
      isActive: false
    }
  },
    watch: {
  wallet(value){
      // binding this to the data value in the email input
      this.wallet = value;
      this.checker(value);
    }
  },
  components: {
  
  },
  methods: {

 async emergency() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const stakeContract = new ethers.Contract(oldfarmAddress, farmABI, signer);
      let action = await stakeContract.emergencyWithdraw(0)
      const tx3 = await action.wait()
      const dimt3 = tx3.events[0];
      console.log(dimt3, tx3, action)
      alert(`Unstaked your LP successfully. You can now restake your LP tokens`)
 },  

async withdraw() {

       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const stakeContract = new ethers.Contract(oldfarmAddress, farmABI, signer);
      //let stakedLp2 = await stakeContract.userInfo(0, signerAddress)
      let stakedLp = window.document.getElementById('lpStaked').value
      //let stakedLpAmount2 = ethers.utils.formatUnits(stakedLp, 18)
      alert(stakedLp)
      let action = await stakeContract.withdraw(0, `${stakedLp}`)

      const tx2 = await action.wait()
      const dimt2 = tx2.events[0];
      console.log(dimt2, tx2, action)
      alert(`Unstaked ${stakedLp} successfully. You can now restake your LP tokens`)
      window.location.replace("https://bananaswap.app/#/earn");
   
    /*
      let supply = await glmjContract.totalSupply()
      supply = supply.toNumber();
      let owned = await glmjContract.walletOfOwner(signerAddress)
     let owned3 = owned.length
      console.log(glmjAddress, glmjABI, bananasContract, supply)
      await alert(`${signerAddress} connected`)

       let explain2 = window.document.getElementById('explain')
       */
},

    async connect() {

       const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const stakeContract = new ethers.Contract(oldfarmAddress, farmABI, signer);
      let stakedLp3 = await stakeContract.userInfo(0, signerAddress)
       
       let inactivelip = window.document.getElementById('inactive')
      
      //let stakedLpAmount2 = ethers.utils.formatUnits(stakedLp3, 18)
     // console.log(Number(stakedLpAmount2).toFixed(4))
      inactivelip.innerText= ` ${stakedLp3}`
 
       let explain2 = window.document.getElementById('explain')
       let mintForm2 = window.document.getElementById('mintForm')
        let connect2 = window.document.getElementById('connectBtn')
       explain2.style.display = "block";
       mintForm2.style.display = "block";
      connect2.style.display = "none";
 
    },
   
  },
}
</script>

<style>
#main {
  max-height: 100% !important;
}

html {
   overflow-y: scroll;
   
}



html, body {
  margin:0;
  padding:0;
  height:100%;
   

}

body {
  font-family: 'Bree Serif', serif;
}

#stats {
  margin: 2rem 0;
}

#mintNb {
  min-height: 25px;
  min-width: 55px;
  max-width: 50px;
  margin-top: 1rem;
}

#totalSupply {
  margin: 1rem 0;
}

#tokensOwned {
  margin: 1rem 0;
}

#mintBtn {
  margin-left: 2rem;
  padding: 5px 15px;
  color: white;
  font-family: 'Bree Serif', serif;
  font-size: 150%;
  background-color: #339933;
  cursor: grab;
}

#connectBtn {
  height: 350px;
  width:  400px;
  margin:  4rem;
  padding: 5px 15px;
  color: white;
  font-family: 'Bree Serif', serif;
  font-size: 150%;
  background-color: #339933;
  border-radius: 3%;
  cursor: grab;
}

input, select, textarea{
    color: #339933;
    font-family: 'Bree Serif', serif;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    font-size: 15pt;
}
#mintForm {
  margin: 1.3rem 0 3rem;
  display: none;
}


.mintHeader {
  color: #F7CA18;
  margin: 0;
  padding: 0;
}

#explain {
  color: white !important;
  padding-top: 0;
  margin-top: 0;
  font-size: 16pt;
  display: none;
}

body {

}
.inactive {
  display: none;
}


#logo {
  max-height: 400px;
  max-width: auto;
}
@media only screen and (max-width: 600px) {
.links {
  font-size: 18pt !important;
  margin: 1rem;
}
#logo {
max-width: 70vw;
display: none;
}
#connectBtn {
    height: 350px;
  width:  400px;
  margin:  0;
  padding: 0.8rem 0.9rem;
}
}

.links {
   font-size: 30pt;

}
a {
 margin: 0 3rem;
 color: #339933;
 text-decoration: none;
font-family: 'Bree Serif', serif;
}

p {
  font-family: 'Bree Serif', serif;
}
a:hover {
color: #cc6633;
text-decoration: underline;
	}

.footer {
  margin-top: 3rem;
   font-size: 13pt;
 color: #cc6633;
 text-decoration: none;
 font-family: 'Bree Serif', serif;
 text-align: center;
 margin-right: auto;
 margin-left: auto;
}
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Staatliches&display=swap');
</style>